import { useCalendar } from "@/lib/calendar";
import { endOfDay, format, startOfDay } from "date-fns";
import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { fetcher } from "@/lib/fetcher";
import { FetchError } from "@/components/error";
import { Task } from "@/types/task";
import { useTaskSheet } from "@/lib/sheet";
import { TaskCounts } from "./ui/count";
import { TaskList } from "./ui/render";
import { Loader } from "../ui/loader";

export function DayCalendar() {
  const { t } = useTranslation();
  const { locale, moveDate, actualDate } = useCalendar();
  const { filters } = useTaskSheet();

  const { data, error } = useSWR<{ tasks: Task[] }>(
    `/v1/dashboard/calendar/range/${startOfDay(
      actualDate
    ).getTime()}/${endOfDay(actualDate).getTime()}`,
    fetcher
  );

  if (error) return <FetchError />;

  return (
    <section className="w-full h-full flex flex-col">
      <header className="my-4 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <p className="font-bold text-4xl">
            {format(actualDate, "dd MMMM yyyy", { locale })}
          </p>
          {!data ? (
            <Loader className="h-6 w-6" />
          ) : (
            <TaskCounts tasks={data.tasks} filters={filters} />
          )}
        </div>
        <div className="flex items-center gap-2">
          <Button onClick={moveDate.prevDay}>
            {t("calendar.move.day.prev")}
          </Button>
          <Button onClick={moveDate.today}>{t("calendar.move.today")}</Button>
          <Button onClick={moveDate.nextDay}>
            {t("calendar.move.day.next")}
          </Button>
        </div>
      </header>
      <section className="w-full">
        {data && (
          <TaskList tasks={data.tasks} filters={filters} displayAsCalendar />
        )}
      </section>
    </section>
  );
}
