import { useState, useRef, useCallback, useEffect, useMemo } from "react"
import useSWR from "swr"
import { fetcher } from "@/lib/fetcher"
import { DateRange } from "react-day-picker"
import { startOfDay, endOfDay } from "date-fns"
import { Table, TableBody, TableCell, TableHeader, TableRow } from "@/components/ui/table"
import { Button } from "@/components/ui/button"
import { Loader2, ChevronDown, ChevronUp, Paperclip, FileCheck, Search, X, Settings2, Download } from "lucide-react"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { baseURL } from "@/globals";
import { useTranslation } from "react-i18next";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { Badge } from "@/components/ui/badge"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { ExportModal } from "@/components/ui/export-modal"
import * as XLSX from 'xlsx';
import { toast } from "@/components/ui/use-toast"
import { PaginationPages } from "@/components/ui/paginationPages"


interface TasksMetricsData {
  tasksMetrics: {
    allTasks: {
      id: string;
      title: string;
      description: string;
      status: string;
      deadline: string;
      finished_at: string;
      created_at: string;
      importance: string;
      price: number;
      quantity: number;
      attached_files: string[];
      written_ubication: string;
      final_files: string[];
      assigned_to?: {
        id: string;
        name: string;
      };
      assigned_by?: {
        id: string;
        name: string;
      };
    }[];
    byAssignedTo: any;
    byAssignedBy: any;
    pagination: {
      total: number;
      hasMore: boolean;
    };
  };
}

interface TableTaskProps {
  taskIds?: string[];
  selectedProjects: string[];
  selectedPersons?: string[];
  selectedRange?: DateRange;
  personId?: string;
  channelId?: string;
  status?: string;
}

interface SearchFilters {
  title?: string;
  assigned_to?: string;
  assigned_by?: string;
}

interface TypeFilter {
  id: string;
  type: "status" | "importance";
  value: string;
}

interface ExportOption {
  value: number | 'all' | 'custom';
  label: string;
}

export function TableTask({ taskIds, selectedProjects, selectedPersons, selectedRange, personId, channelId, status }: TableTaskProps) {
  const [page, setPage] = useState(1)
  const [sortColumn, setSortColumn] = useState<string | null>(null)
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc")
  const [columnWidths, setColumnWidths] = useState<Record<string, number>>({
    assigned_by: 150,
    assigned_to: 150,
    title: 200,
    description: 300,
    status: 100,
    deadline: 100,
    price: 100,
    quantity: 100,
    importance: 100,
    files: 100,
    written_ubication: 150,
    created_at: 150,
    finished_at: 150,
  });
  const resizingColumn = useRef<string | null>(null);
  const { t } = useTranslation();
  const [columnFilters, setColumnFilters] = useState<Record<string, boolean>>({
    assigned_by: true,
    assigned_to: true,
    title: true,
    description: true,
    status: true,
    deadline: true,
    price: true,
    quantity: false,
    importance: false,
    files: true,
    written_ubication: false,
    created_at: false,
    finished_at: false,
  });
  const [localSearchFilters, setLocalSearchFilters] = useState<SearchFilters>(
    {}
  );
  const [activeSearch, setActiveSearch] = useState<string | null>(null);
  const [universalSearch, setUniversalSearch] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [searchField, setSearchField] = useState<string>("all");
  const [activeTypeFilters, setActiveTypeFilters] = useState<TypeFilter[]>([]);
  const exportOptions: ExportOption[] = [
    { value: 15, label: '15 tasks' },
    { value: 25, label: '25 tasks' },
    { value: 50, label: '50 tasks' },
    { value: 'custom', label: 'Custom amount' },
    { value: 'all', label: 'All tasks' },
  ];
  const [isExporting, setIsExporting] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [exportProgress, setExportProgress] = useState(0);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [fetchSize, _setFetchSize] = useState<number | 'all'>(15);


  useEffect(() => {
    setPage(1);
    mutate(undefined, { revalidate: true });
  }, [personId, channelId, status, taskIds]);

  const toggleColumn = (columnId: string) => {
    setColumnFilters((prev) => ({
      ...prev,
      [columnId]: !prev[columnId],
    }));
  };

  const visibleColumns = Object.entries(columnFilters)
    .filter(([_, enabled]) => enabled)
    .map(([key]) => key);

  const handleUniversalSearch = async (searchTerm: string, shouldFetch: boolean = false) => {
    setUniversalSearch(searchTerm);
    
    if (!shouldFetch) return;

    setIsSearching(true);
    setPage(1);
    
    try {
      const baseParams = {
        search: searchTerm,
        searchField: searchField,
        page: '1',
        activeTypeFilters: JSON.stringify(activeTypeFilters),
        ...(sortColumn && {
          sort_by: sortColumn,
          sort_direction: sortDirection
        }),
        ...((selectedPersons?.length ?? 0) > 0 && {
          people: JSON.stringify(selectedPersons),
        }),
        ...((selectedProjects?.length ?? 0) > 0 && {
          projects: JSON.stringify(selectedProjects),
        }),
        ...(selectedRange?.from && {
          from: String(startOfDay(selectedRange.from).getTime()),
        }),
        ...(selectedRange?.to && {
          to: String(endOfDay(selectedRange.to).getTime()),
        }),
        ...localSearchFilters
      };

      let endpoint;
      if (personId) {
        endpoint = `/v1/metrics/tasks-by-person-status?${new URLSearchParams({
          person_id: personId,
          ...(status && status !== 'TOTAL' && { status }),
          ...baseParams
        })}`;
      } else if (channelId) {
        endpoint = `/v1/metrics/tasks-by-channel-status?${new URLSearchParams({
          channel_id: channelId,
          ...(status && status !== 'TOTAL' && { status }),
          ...baseParams
        })}`;
      } else if (taskIds) {
        endpoint = `/v1/metrics/tasks-by-ids?${new URLSearchParams({
          ids: JSON.stringify(taskIds),
          ...baseParams
        })}`;
      } else {
        endpoint = `/v1/metrics/tasks?${new URLSearchParams(baseParams)}`;
      }

      const result = await fetcher(endpoint);
      await mutate(result, false);

    } catch (error) {
      console.error('Search failed:', error);
    } finally {
      setIsSearching(false);
    }
  };

  const { data, error, mutate, isLoading } = useSWR<TasksMetricsData>(
    (() => {
      const baseParams = {
        page: String(page),
        ...(sortColumn && {
          sort_by: sortColumn,
          sort_direction: sortDirection
        }),
        ...((selectedPersons?.length ?? 0) > 0 && {
          people: JSON.stringify(selectedPersons),
        }),
        ...((selectedProjects?.length ?? 0) > 0 && {
          projects: JSON.stringify(selectedProjects),
        }),
        ...(selectedRange?.from && {
          from: String(startOfDay(selectedRange.from).getTime()),
        }),
        ...(selectedRange?.to && {
          to: String(endOfDay(selectedRange.to).getTime()),
        }),
        ...localSearchFilters
      };

      let endpoint;
      if (personId) {
        endpoint = `/v1/metrics/tasks-by-person-status?${new URLSearchParams({
          person_id: personId,
          ...(status && status !== 'TOTAL' && { status }),
          ...baseParams
        })}`;
      } else if (channelId) {
        endpoint = `/v1/metrics/tasks-by-channel-status?${new URLSearchParams({
          channel_id: channelId,
          ...(status && status !== 'TOTAL' && { status }),
          
          ...baseParams
        })}`;
      } else if (taskIds) {
        endpoint = `/v1/metrics/tasks-by-ids?${new URLSearchParams({
          ids: JSON.stringify(taskIds),
          ...baseParams
        })}`;
      } else {
        endpoint = `/v1/metrics/tasks?${new URLSearchParams(baseParams)}`;
      }

      return endpoint;
    })(),
    fetcher,
    {
      keepPreviousData: true,
      revalidateOnFocus: false
    }
  );

  // Get tasks first from data
  const tasks = data?.tasksMetrics?.allTasks || [];

  const applyFilters = (tasks: TasksMetricsData['tasksMetrics']['allTasks']) => {
    return tasks.filter(task => {
      // Check if task matches search criteria first
      if (universalSearch) {
        const searchTerm = universalSearch.toLowerCase();
        let searchMatch = false;
        
        switch (searchField) {
          case "assigned_by":
            searchMatch = task.assigned_by?.name?.toLowerCase().includes(searchTerm) || false;
            break;
          case "assigned_to":
            searchMatch = task.assigned_to?.name?.toLowerCase().includes(searchTerm) || false;
            break;
          case "title":
            searchMatch = task.title?.toLowerCase().includes(searchTerm) || false;
            break;
          case "description":
            searchMatch = task.description?.toLowerCase().includes(searchTerm) || false;
            break;
          case "status":
            searchMatch = task.status?.toLowerCase().includes(searchTerm) || false;
            break;
          case "deadline":
            searchMatch = new Date(task.deadline).toLocaleDateString().toLowerCase().includes(searchTerm) || false;
            break;
          case "price":
            searchMatch = task.price?.toString().includes(searchTerm) || false;
            break;
          case "importance":
            searchMatch = task.importance?.toLowerCase().includes(searchTerm) || false;
            break;
          case "written_ubication":
            searchMatch = task.written_ubication?.toLowerCase().includes(searchTerm) || false;
            break;
          case "created_at":
            searchMatch = new Date(task.created_at).toLocaleDateString().toLowerCase().includes(searchTerm) || false;
            break;
          case "finished_at":
            searchMatch = (task.finished_at && new Date(task.finished_at).toLocaleDateString().toLowerCase().includes(searchTerm)) || false;
            break;
          case "all":
          default:
            searchMatch = (
              (task.title?.toLowerCase().includes(searchTerm) ||
              task.description?.toLowerCase().includes(searchTerm) ||
              task.assigned_by?.name?.toLowerCase().includes(searchTerm) ||
              task.assigned_to?.name?.toLowerCase().includes(searchTerm) ||
              task.status?.toLowerCase().includes(searchTerm) ||
              task.importance?.toLowerCase().includes(searchTerm) ||
              task.written_ubication?.toLowerCase().includes(searchTerm) ||
              task.price?.toString().includes(searchTerm) ||
              new Date(task.deadline).toLocaleDateString().toLowerCase().includes(searchTerm) ||
              new Date(task.created_at).toLocaleDateString().toLowerCase().includes(searchTerm) ||
              (task.finished_at && new Date(task.finished_at).toLocaleDateString().toLowerCase().includes(searchTerm)))
            ) || false;
            break;
        }

        if (!searchMatch) return false;
      }

      // Then check if task matches type filters (status and importance)
      if (activeTypeFilters.length > 0) {
        const statusFilters = activeTypeFilters.filter(f => f.type === 'status').map(f => f.value);
        const importanceFilters = activeTypeFilters.filter(f => f.type === 'importance').map(f => f.value);

        if (statusFilters.length > 0 && !statusFilters.includes(task.status)) return false;
        if (importanceFilters.length > 0 && !importanceFilters.includes(task.importance)) return false;
      }

      return true;
    });
  };

  const filteredTasks = useMemo(() => {
    return applyFilters(tasks);
  }, [tasks, universalSearch, searchField, activeTypeFilters]);

  const totalAvailable = data?.tasksMetrics?.pagination?.total || 0;

  const handleSort = (column: string) => {
    // Skip sorting for these columns
    if (column === "files" || column === "description") return;

    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
    setPage(1);
    mutate(undefined, { revalidate: true });
  };

  const handleResizeStart = useCallback((column: string) => {
    resizingColumn.current = column;
  }, []);

  const handleResizeEnd = useCallback(() => {
    resizingColumn.current = null;
  }, []);

  const handleResize = useCallback((e: React.MouseEvent) => {
    if (resizingColumn.current) {
      const newWidth = Math.max(
        100,
        e.clientX - e.currentTarget.getBoundingClientRect().left
      );
      setColumnWidths((prev) => ({
        ...prev,
        [resizingColumn.current!]: newWidth,
      }));
    }
  }, []);

  const renderCell = (task: any, column: string) => {
    switch (column) {
      case "assigned_by":
        return task.assigned_by?.name || t("table.unassigned");
      case "assigned_to":
        return task.assigned_to?.name || t("table.unassigned");
      case "title":
        return task.title;
      case "status":
        const statusColors: Record<string, string> = {
          PENDING: "#e5e7eb",
          IN_PROGRESS: "#fefa8a",
          IN_REVIEW: "#3B82F6",
          DONE: "#86efac",
          DONE_WITH_ISSUES: "#fdba74",
        };

        const statusDisplay: Record<string, string> = {
          PENDING: t("metrics.charts.status.status.PENDING"), // "Pendiente" / "Pending"
          IN_PROGRESS: t("metrics.charts.status.status.IN_PROGRESS"), // "En Progreso" / "In Progress"
          DONE: t("metrics.charts.status.status.DONE"), // "Completada" / "Completed"
          DONE_WITH_ISSUES: t("metrics.charts.status.status.DONE_WITH_ISSUES"), // "Completada con Problemas" / "Completed with Issues"
          IN_REVIEW: t("metrics.charts.status.status.IN_REVIEW"), // "En Revisión" / "In Review"
        };

        return (
          <div
            className="px-2 py-1 rounded-md text-white text-sm inline-flex items-center justify-center"
            style={{
              backgroundColor: statusColors[task.status] || "#6C788A",
              minWidth: "100px",
            }}
          >
            {statusDisplay[task.status] || task.status}
          </div>
        );
      case "deadline":
        return new Date(task.deadline).toLocaleDateString();
      case "price":
        return task.price ? `$${task.price.toFixed(2)}` : "$0.00";
      case "files":
        return (
          <div className="flex space-x-2">
            <Popover>
              <PopoverTrigger
                asChild
                disabled={
                  !task.attached_files || task.attached_files.length === 0
                }
              >
                <span
                  className={`inline-flex items-center ${
                    task.attached_files?.length > 0
                      ? "cursor-pointer hover:text-primary"
                      : ""
                  }`}
                  title={t("table.attached_files")}
                >
                  <Paperclip className="inline" />{" "}
                  {task.attached_files?.length || 0}
                </span>
              </PopoverTrigger>
              {task.attached_files?.length > 0 && (
                <PopoverContent className="w-auto p-2">
                  <div className="flex flex-col space-y-1">
                    {task.attached_files.map((file: any, index: number) => (
                      <a
                        key={index}
                        href={`${baseURL}/v1/blob/file/${file.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:underline"
                      >
                        {t("table.file")} {index + 1}
                      </a>
                    ))}
                  </div>
                </PopoverContent>
              )}
            </Popover>

            <Popover>
              <PopoverTrigger
                asChild
                disabled={!task.final_files || task.final_files.length === 0}
              >
                <span
                  className={`inline-flex items-center ${
                    task.final_files?.length > 0
                      ? "cursor-pointer hover:text-primary"
                      : ""
                  }`}
                  title={t("table.final_files")}
                >
                  <FileCheck className="inline" />{" "}
                  {task.final_files?.length || 0}
                </span>
              </PopoverTrigger>
              {task.final_files?.length > 0 && (
                <PopoverContent className="w-auto p-2">
                  <div className="flex flex-col space-y-1">
                    {task.final_files.map((file: any, index: number) => (
                      <a
                        key={index}
                        href={`${baseURL}/v1/blob/file/${file.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:underline"
                      >
                        {t("table.file")} {index + 1}
                      </a>
                    ))}
                  </div>
                </PopoverContent>
              )}
            </Popover>
          </div>
        );
      case "description":
        return task.description.length > 50
          ? `${task.description.substring(0, 50)}...`
          : task.description;
      default:
        return task[column];
    }
  };

  const renderColumnHeader = (column: string) => {
    const searchableColumns: string[] = [];

    if (!searchableColumns.includes(column)) {
      return t(`table.${column}`);
    }

    return (
      <div
        className="flex items-center gap-2"
        onClick={(e) => e.stopPropagation()}
      >
        {activeSearch === column ? (
          <div className="flex items-center gap-2">
            <Input
              autoFocus
              placeholder={t(`search.${column}`)}
              className="w-40 h-8"
              value={localSearchFilters[column as keyof SearchFilters] || ""}
              onChange={(e) => {
                e.stopPropagation();
                setLocalSearchFilters((prev) => ({
                  ...prev,
                  [column]: e.target.value,
                }));
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
                if (e.key === "Escape") {
                  setActiveSearch(null);
                }
              }}
              onBlur={(e) => {
                if (!e.currentTarget.contains(e.relatedTarget as Node)) {
                  setActiveSearch(null);
                }
              }}
            />
          </div>
        ) : (
          <>
            {t(`table.${column}`)}
            <Search
              className="w-4 h-4 cursor-pointer hover:text-primary"
              onClick={() => setActiveSearch(column)}
            />
            {localSearchFilters[column as keyof SearchFilters] && (
              <X
                className="w-4 h-4 cursor-pointer hover:text-primary"
                onClick={() => {
                  setLocalSearchFilters((prev) => ({ ...prev, [column]: "" }));
                }}
              />
            )}
          </>
        )}
      </div>
    );
  };

  const handleSearchInputChange = (value: string) => {
    setUniversalSearch(value);
    setHasUnsavedChanges(true);
  };

  const addTypeFilter = (type: 'status' | 'importance', value: string) => {
    setActiveTypeFilters(prev => {
      const existingFilter = prev.find(f => f.type === type && f.value === value);
      const newFilters = existingFilter
        ? prev.filter(f => f.id !== existingFilter.id)
        : [...prev, { id: Math.random().toString(36).substr(2, 9), type, value }];
      setHasUnsavedChanges(true);
      return newFilters;
    });
  };

  const removeTypeFilter = (filterId: string) => {
    setActiveTypeFilters(prev => {
      const newFilters = prev.filter(filter => filter.id !== filterId);
      setHasUnsavedChanges(true);
      return newFilters;
    });
  };

  const handleExport = async (option: number | 'all' | 'custom') => {
    try {
      if (option === 'custom') {
        setShowExportModal(true);
        return;
      }

      setIsExporting(true);
      
      let amount: number | 'all' = option === 'all' ? 'all' : option as number;
      await exportTasks(amount);
      
    } catch (error) {
      console.error('Export failed:', error);
      toast({
        title: t('export.error.title'),
        description: t('export.error.description'),
        variant: 'destructive',
      });
    } finally {
      setIsExporting(false);
    }
  };

  const handleCustomExport = async (amount: number) => {
    try {
      setIsExporting(true);
      await exportTasks(amount);
    } catch (error) {
      console.error('Export failed:', error);
      toast({
        title: t('export.error.title'),
        description: t('export.error.description'),
        variant: 'destructive',
      });
    } finally {
      setIsExporting(false);
    }
  };

  const formatDate = (date: string | Date) => {
    if (!date) return '';
    return new Date(date).toLocaleDateString();
  };

  const prepareTaskForExport = (task: any) => {
    return {
      [t('table.title')]: task.title,
      [t('table.description')]: task.description,
      [t('table.assigned_by')]: task.assigned_by?.name || t('table.unassigned'),
      [t('table.assigned_to')]: task.assigned_to?.name || t('table.unassigned'),
      [t('table.status')]: t(`metrics.charts.status.status.${task.status}`),
      [t('table.deadline')]: formatDate(task.deadline),
      [t('table.created_at')]: formatDate(task.created_at),
      [t('table.finished_at')]: task.finished_at ? formatDate(task.finished_at) : '',
      [t('table.price')]: task.price ? `$${task.price.toFixed(2)}` : '$0.00',
      [t('table.importance')]: t(`ui.importance.${task.importance}`),
      [t('table.written_ubication')]: task.written_ubication || '',
      [t('table.attached_files')]: task.attached_files?.length || 0,
      [t('table.attached_files_urls')]: task.attached_files?.map((file: any) => 
        `${baseURL}/v1/blob/file/${file.id}`
      ).join(', ') || '',
      [t('table.final_files')]: task.final_files?.length || 0,
      [t('table.final_files_urls')]: task.final_files?.map((file: any) => 
        `${baseURL}/v1/blob/file/${file.id}`
      ).join(', ') || ''
    };
  };

  const exportTasks = async (amount: number | 'all') => {
    try {
      setIsExporting(true);
      setExportProgress(0);
      
      // Create base params with ALL filters
      const baseParams = {
        ...(sortColumn && {
          sort_by: sortColumn,
          sort_direction: sortDirection
        }),
        ...((selectedPersons?.length ?? 0) > 0 && {
          people: JSON.stringify(selectedPersons),
        }),
        ...((selectedProjects?.length ?? 0) > 0 && {
          projects: JSON.stringify(selectedProjects),
        }),
        ...(selectedRange?.from && {
          from: String(startOfDay(selectedRange.from).getTime()),
        }),
        ...(selectedRange?.to && {
          to: String(endOfDay(selectedRange.to).getTime()),
        }),
        ...localSearchFilters
      };

      // Maintain the specific filters based on the chart bar clicked
      let endpoint;
      if (personId) {
        endpoint = `/v1/metrics/tasks-by-person-status?${new URLSearchParams({
          person_id: personId,
          ...(status && status !== 'TOTAL' && { status }),
          ...baseParams
        })}`;
      } else if (channelId) {
        endpoint = `/v1/metrics/tasks-by-channel-status?${new URLSearchParams({
          channel_id: channelId,
          ...(status && status !== 'TOTAL' && { status }),
          ...baseParams
        })}`;
      } else if (taskIds) {
        endpoint = `/v1/metrics/tasks-by-ids?${new URLSearchParams({
          ids: JSON.stringify(taskIds),
          ...baseParams
        })}`;
      } else {
        endpoint = `/v1/metrics/tasks?${new URLSearchParams(baseParams)}`;
      }

      console.log('Export endpoint being used:', endpoint);

      // Fetch all tasks that match the filters
      const allTasks = [];
      let currentPage = 1;
      let hasMore = true;
      const tasksNeeded = amount === 'all' ? Infinity : amount;

      while (hasMore && allTasks.length < tasksNeeded) {
        const response = await fetcher(`${endpoint}&page=${currentPage}`);
        let newTasks = response.tasksMetrics.allTasks;

        // Apply universal search filter if it exists
        if (universalSearch || activeTypeFilters.length > 0) {
          newTasks = applyFilters(newTasks);
        }

        allTasks.push(...newTasks);
        hasMore = response.tasksMetrics.pagination.hasMore;
        currentPage++;

        // Update progress
        const progress = Math.min((allTasks.length / tasksNeeded) * 100, 100);
        setExportProgress(progress);
        
        // Small delay to prevent overwhelming the server
        await new Promise(resolve => setTimeout(resolve, 100));
      }

      // Slice to the requested amount if needed
      const tasksToExport = amount === 'all' ? allTasks : allTasks.slice(0, amount);

      // Prepare data for export
      const exportData = tasksToExport.map(prepareTaskForExport);

      // Create worksheet
      const ws = XLSX.utils.json_to_sheet(exportData);

      // Create workbook
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Tasks');

      // Generate filename with current date
      const date = new Date().toISOString().split('T')[0];
      const filename = `tasks_export_${date}.xlsx`;

      // Save file
      XLSX.writeFile(wb, filename);

      toast({
        title: t('export.success.title'),
        description: t('export.success.description', { count: tasksToExport.length }),
        duration: 3000,
      });

    } catch (error) {
      console.error('Export failed:', error);
      toast({
        title: t('export.error.title'),
        description: t('export.error.description'),
        variant: 'destructive',
        duration: 3000,
      });
    } finally {
      setIsExporting(false);
      setExportProgress(0);
    }
  };

  const executeSearch = () => {
    handleUniversalSearch(universalSearch, true);
    setHasUnsavedChanges(false);
  };

  if (isLoading) return <Loader2 className="w-6 h-6 animate-spin" />
  if (error) return <div className="text-red-500">Error loading data</div>

  console.log("tasksMetrics:", data?.tasksMetrics);

  return (
    <div className="space-y-4">
      <div className="space-y-2">
        <div className="relative">
          <div className="flex items-center gap-2 px-4 py-2 bg-background border-b">
            <div className="flex-1 flex items-center gap-3">
              <div className="flex items-center gap-2">
                <Search className="w-4 h-4 text-muted-foreground" />
                <Select value={searchField} onValueChange={setSearchField}>
                  <SelectTrigger className="w-[80px] h-8">
                    <SelectValue placeholder={t('search.select_field')} />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="all">
                      {t("search.all_fields")}
                    </SelectItem>
                    <SelectItem value="assigned_by">
                      {t("table.assigned_by")}
                    </SelectItem>
                    <SelectItem value="assigned_to">
                      {t("table.assigned_to")}
                    </SelectItem>
                    <SelectItem value="title">{t("table.title")}</SelectItem>
                    <SelectItem value="description">
                      {t("table.description")}
                    </SelectItem>
                    <SelectItem value="status">{t("table.status")}</SelectItem>
                    <SelectItem value="deadline">
                      {t("table.deadline")}
                    </SelectItem>
                    <SelectItem value="price">{t("table.price")}</SelectItem>
                    <SelectItem value="importance">
                      {t("table.importance")}
                    </SelectItem>
                    <SelectItem value="written_ubication">
                      {t("table.written_ubication")}
                    </SelectItem>
                    <SelectItem value="created_at">
                      {t("table.created_at")}
                    </SelectItem>
                    <SelectItem value="finished_at">
                      {t("table.finished_at")}
                    </SelectItem>
                  </SelectContent>
                </Select>
                <span className="text-sm text-muted-foreground">
                  {t("search.total_results", {
                    count: universalSearch
                      ? filteredTasks.length
                      : data?.tasksMetrics?.pagination?.total || 0,
                  })}
                </span>
              </div>
              <div className="flex-1 flex items-center gap-2">
                <Input
                  placeholder={t(`search.placeholder.${searchField}`)}
                  value={universalSearch}
                  onChange={(e) => handleSearchInputChange(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      executeSearch();
                    }
                  }}
                  className="flex-1 h-8 bg-background"
                  disabled={isSearching}
                />
                {hasUnsavedChanges && (
                  <Button
                    onClick={executeSearch}
                    className="h-8 bg-purple-600 hover:bg-purple-700 text-white"
                    disabled={isSearching}
                  >
                    {isSearching ? (
                      <Loader2 className="w-4 h-4 animate-spin" />
                    ) : (
                      <Search className="w-4 h-4" />
                    )}
                  </Button>
                )}
                {isSearching && !hasUnsavedChanges && (
                  <Loader2 className="w-4 h-4 animate-spin absolute right-3" />
                )}
              </div>
            </div>
            <div className="flex items-center gap-2">
              {universalSearch && (
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => {
                    handleUniversalSearch('');
                    setHasUnsavedChanges(true);
                  }}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <X className="w-4 h-4" />
                </Button>
              )}

              <Popover>
                <PopoverTrigger asChild>
                  <Button variant="outline" size="sm">
                    <Settings2 className="w-4 h-4 mr-2" />
                    {t("table.type_filters")}
                  </Button>
                </PopoverTrigger>
                <PopoverContent align="end" className="w-56">
                  <div className="font-medium">
                    {t("table.add_type_filter")}
                  </div>
                  <div className="h-px bg-gray-100 my-2" />
                  <div className="space-y-4">
                    <div>
                      <div className="font-medium text-sm mb-1">
                        {t("table.status")}
                      </div>
                      <div className="space-y-2">
                        {[
                          "PENDING",
                          "IN_PROGRESS",
                          "DONE",
                          "DONE_WITH_ISSUES",
                          "IN_REVIEW",
                        ].map((status) => (
                          <div
                            key={status}
                            className="flex items-center space-x-2"
                          >
                            <Checkbox
                              checked={activeTypeFilters.some(
                                (f) => f.type === "status" && f.value === status
                              )}
                              onCheckedChange={() =>
                                addTypeFilter("status", status)
                              }
                            />
                            <span>
                              {t(`metrics.charts.status.status.${status}`)}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div>
                      <div className="font-medium text-sm mb-1">
                        {t("table.importance")}
                      </div>
                      <div className="space-y-2">
                        {["LOW", "MEDIUM", "HIGH"].map((importance) => (
                          <div
                            key={importance}
                            className="flex items-center space-x-2"
                          >
                            <Checkbox
                              checked={activeTypeFilters.some(
                                (f) =>
                                  f.type === "importance" &&
                                  f.value === importance
                              )}
                              onCheckedChange={() =>
                                addTypeFilter("importance", importance)
                              }
                            />
                            <span>
                              {t(
                                `table.importance_${importance.toLowerCase()}`
                              )}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </PopoverContent>
              </Popover>

              <Popover>
                <PopoverTrigger asChild>
                  <Button variant="outline" size="sm">
                    <Settings2 className="w-4 h-4 mr-2" />
                    {t("table.columns")}
                  </Button>
                </PopoverTrigger>
                <PopoverContent align="end" className="w-[200px] p-2">
                  {Object.keys(columnFilters).map((columnId) => (
                    <div
                      key={columnId}
                      className="flex items-center space-x-2 p-2"
                    >
                      <Checkbox
                        checked={columnFilters[columnId]}
                        onCheckedChange={() => toggleColumn(columnId)}
                        id={columnId}
                      />
                      <label
                        htmlFor={columnId}
                        className="text-sm cursor-pointer flex-grow"
                      >
                        {t(`table.${columnId}`)}
                      </label>
                    </div>
                  ))}
                </PopoverContent>
              </Popover>

              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline" size="sm" disabled={isExporting}>
                    <Download className="w-4 h-4 mr-2" />
                    {isExporting ? t('table.exporting') : t('table.export')}
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  {exportOptions.map((option) => (
                    <DropdownMenuItem
                      key={option.value}
                      onClick={() => handleExport(option.value)}
                    >
                      {t(`table.export_${option.value}`)}
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap gap-2">
          {activeTypeFilters.map((filter) => (
            <Badge
              key={filter.id}
              variant="secondary"
              className="px-2 py-1 bg-purple-100 text-purple-800 hover:bg-purple-200 transition-colors duration-200"
            >
              {filter.type === "status"
                ? t(`metrics.charts.status.status.${filter.value}`)
                : t(`table.importance_${filter.value.toLowerCase()}`)}
              <Button
                variant="ghost"
                size="icon"
                onClick={() => removeTypeFilter(filter.id)}
                className="h-4 w-4 ml-1 hover:bg-transparent hover:text-purple-900"
              >
                <X className="h-3 w-3" />
              </Button>
            </Badge>
          ))}
        </div>
      </div>

      <div
        className="space-y-4 overflow-x-auto"
        onMouseMove={handleResize}
        onMouseUp={handleResizeEnd}
        onMouseLeave={handleResizeEnd}
      >
        <Table>
          <TableHeader>
            <TableRow>
              {visibleColumns.map((column) => (
                <th
                  key={column}
                  onClick={() => handleSort(column)}
                  className="cursor-pointer"
                >
                  {renderColumnHeader(column)}
                  {sortColumn === column && (
                    <span className="ml-2">
                      {sortDirection === "asc" ? (
                        <ChevronUp className="inline w-4 h-4" />
                      ) : (
                        <ChevronDown className="inline w-4 h-4" />
                      )}
                    </span>
                  )}
                </th>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {filteredTasks.length > 0 ? (
              filteredTasks.map((task) => (
                <TableRow key={task.id}>
                  {visibleColumns.map((column) => (
                    <TableCell
                      key={column}
                      style={{ width: `${columnWidths[column]}px` }}
                      onMouseDown={() => handleResizeStart(column)}
                    >
                      {renderCell(task, column)}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={visibleColumns.length}
                  className="text-center py-4"
                >
                  {t("table.no_tasks")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        
        <div className="flex items-center justify-between">
          <div className="text-sm text-muted-foreground">
            {t('table.showing_results', {
              from: (page - 1) * (typeof fetchSize === 'number' ? fetchSize : totalAvailable) + 1,
              to: Math.min(page * (typeof fetchSize === 'number' ? fetchSize : totalAvailable), totalAvailable),
              total: totalAvailable
            })}
          </div>
          
          <PaginationPages
            page={page}
            totalPages={Math.ceil(totalAvailable / (fetchSize === 'all' ? totalAvailable : fetchSize))}
            setPage={(newPage) => {
              setPage(newPage);
              mutate(undefined, { revalidate: true });
            }}
          />
        </div>
      </div>
      {showExportModal && (
        <ExportModal
          isOpen={showExportModal}
          onClose={() => setShowExportModal(false)}
          onConfirm={handleCustomExport}
          totalTasks={filteredTasks.length}
          isExporting={isExporting}
          progress={exportProgress}
        />
      )}
    </div>
  );
}
