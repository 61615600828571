import { RadialBarChart, RadialBar, PolarAngleAxis } from "recharts";
import { useTranslation } from "react-i18next";

interface CompletenessChartProps {
  total: number;
  completed: number;
  completedOnTime: number;
}

export function CompletenessChart({
  total,
  completed,
  completedOnTime,
}: CompletenessChartProps) {
  const { t } = useTranslation();

  const percentage = total > 0 ? Math.round((completed / total) * 100) : 0;
  const onTimePercentage = completed > 0 ? Math.round((completedOnTime / completed) * 100) : 0;

  const data = [
    {
      name: "Completed",
      value: percentage,
      fill: "#8b5cf6",
    },
  ];

  return (
    <div className="flex flex-col items-center p-4 bg-white rounded-lg shadow-md">
      <h2 className="text-xl font-semibold mb-2">{t("metrics.completeness.title")}</h2>
      <p className="text-sm text-gray-500 mb-4">
        {t("metrics.completeness.description")}
      </p>
      <div className="w-64 h-64 relative">
        <RadialBarChart
          width={256}
          height={256}
          innerRadius="80%"
          outerRadius="100%"
          data={data}
          startAngle={180}
          endAngle={0}
        >
          <PolarAngleAxis
            type="number"
            domain={[0, 100]}
            angleAxisId={0}
            tick={false}
          />
          <RadialBar
            background
            dataKey="value"
            cornerRadius={10}
            fill="#3b82f6"
          />
          <text
            x="50%"
            y="50%"
            textAnchor="middle"
            dominantBaseline="middle"
            className="fill-gray-900 text-3xl font-bold"
          >
            {percentage}%
          </text>
        </RadialBarChart>
        <div className="absolute inset-0 flex flex-col items-center justify-center">
          <p className="text-gray-500 mt-12">{t("metrics.completeness.completed")}</p>
        </div>
      </div>

      <div className="w-full -mt-4">
        <p className="text-gray-500 text-center">
          {t("metrics.completeness.ontime")}
        </p>
        <p className="text-xl font-bold text-gray-900 mb-2 text-center">
          {onTimePercentage}%
        </p>
        <div className="overflow-hidden h-4 text-xs flex rounded-lg bg-green-200">
          <div
            style={{ width: `${onTimePercentage}%` }}
            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-600 rounded-lg"
          ></div>
        </div>
      </div>
    </div>
  );
}
