import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useSearch } from "wouter";
import { useAuth } from "@/lib/auth";
import { ShowWithPermissions } from "@/components/permissions";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import {
  HomeIcon,
  SquareKanbanIcon,
  CalendarIcon,
  NotebookPenIcon,
  HandshakeIcon,
  PieChartIcon,
  Building2Icon,
  UsersIcon,
  SettingsIcon,
  ShieldIcon,
  GlobeIcon,
  ExternalLinkIcon,
  CreditCardIcon,
  Loader2Icon,
} from "lucide-react";
import { Freeze } from "@/components/freeze";
import { ActivateAccount } from "@/components/activate-account";
import { authedFetch } from "@/lib/fetcher";
import { useTaskSheet } from "@/lib/sheet";

function DashLink({
  href,
  children,
  isCollapsed,
}: {
  href: string;
  children: ReactNode;
  isCollapsed: boolean;
}) {
  const [location] = useLocation();

  return (
    <Link
      className={`
        ${
          (href === "/" ? location === href : location.startsWith(href))
            ? "bg-purple-100 text-purple-600"
            : "text-purple-500 hover:text-purple-900"
        } flex items-center gap-3 rounded-lg px-3 py-2 transition-all
        ${isCollapsed ? "justify-center" : ""}
      `}
      href={href}
    >
      {children}
    </Link>
  );
}

function DashButton({
  onClick,
  children,
  isCollapsed,
}: {
  onClick: () => void;
  children: ReactNode;
  isCollapsed: boolean;
}) {
  return (
    <button
      className={`flex items-center gap-3 rounded-lg px-3 py-2 text-purple-500 transition-all hover:text-purple-900 ${
        isCollapsed ? "justify-center" : ""
      }`}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

function PortalLink({ isCollapsed }: { isCollapsed: boolean }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  async function action() {
    if (isLoading) return;
    setIsLoading(true);
    const activate = await authedFetch("/v1/payments/portal", {});
    const data = await activate.json();
    if (data?.url) window.open(data.url, "_blank", "noreferrer");
    setIsLoading(false);
  }

  return (
    <DashButton onClick={action} isCollapsed={isCollapsed}>
      <div
        className={`flex items-center ${
          isCollapsed ? "justify-center" : "justify-between w-full"
        }`}
      >
        <div
          className={`flex items-center gap-3 ${isCollapsed ? "" : "w-full"}`}
        >
          <CreditCardIcon className="h-4 w-4" />
          {!isCollapsed && t("navigation.payments")}
        </div>
      </div>
      {!isCollapsed &&
        (isLoading ? (
          <Loader2Icon className="h-4 w-4 animate-spin" />
        ) : (
          <ExternalLinkIcon className="h-4 w-4" />
        ))}
    </DashButton>
  );
}

export function Dashboard({ children }: { children: ReactNode }) {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const { user, logout } = useAuth();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const { controlSheet } = useTaskSheet();

  const searchParams = useSearch();

  useEffect(() => {
    const formattedParams = new URLSearchParams(searchParams);

    if (formattedParams.get("task")) {
      controlSheet.openWithTask(formattedParams.get("task")!);
    }
  }, []);

  return (
    <div className="flex h-screen w-full overflow-hidden md:rounded-r-3xl">
      <div className="relative flex">
        <aside
          className={`flex flex-col h-full border-r bg-purple-100/40 border-purple-200/40 transition-all duration-300 ${
            isCollapsed ? "w-16" : "w-60"
          }`}
        >
          <div className="flex flex-col justify-between min-h-screen gap-2">
            <div>
              <header className="flex h-[60px] items-center px-6 my-2">
                <div
                  className={`flex items-center gap-2 font-semibold ${
                    isCollapsed ? "justify-center w-full" : ""
                  }`}
                >
                  <div
                    className={`flex items-center justify-cente ${
                      isCollapsed ? "w-10 h-10" : "w-12 h-12"
                    }`}
                  >
                    <img
                      src="https://assets.pulpoo.com/pulpoo.png"
                      alt="Pulpoo logo"
                      className="h-20 w-20 object-contain"
                    />
                  </div>
                  {!isCollapsed && (
                    <p className="text-purple-700 text-xl">Pulpoo</p>
                  )}
                </div>
              </header>
              <div className="flex-1">
                <nav className="grid items-start px-4 text-sm font-medium">
                  <DashLink href="/" isCollapsed={isCollapsed}>
                    <HomeIcon className="h-4 w-4" />
                    {!isCollapsed && t("navigation.home")}
                  </DashLink>
                  <DashLink href="/tasks" isCollapsed={isCollapsed}>
                    <SquareKanbanIcon className="h-4 w-4" />
                    {!isCollapsed && t("navigation.tasks")}
                  </DashLink>
                  <DashLink href="/calendar" isCollapsed={isCollapsed}>
                    <CalendarIcon className="h-4 w-4" />
                    {!isCollapsed && t("navigation.calendar")}
                  </DashLink>
                  <ShowWithPermissions minimun="MODERATOR">
                    <DashLink href="/projects" isCollapsed={isCollapsed}>
                      <NotebookPenIcon className="h-4 w-4" />
                      {!isCollapsed && t("navigation.projects")}
                    </DashLink>
                    <DashLink href="/employees" isCollapsed={isCollapsed}>
                      <HandshakeIcon className="h-4 w-4" />
                      {!isCollapsed && t("navigation.employees")}
                    </DashLink>
                    <DashLink href="/metrics" isCollapsed={isCollapsed}>
                      <PieChartIcon className="h-4 w-4" />
                      {!isCollapsed && t("navigation.metrics")}
                    </DashLink>

                    <Separator className="my-2 bg-purple-500" />

                    <DashLink href="/org" isCollapsed={isCollapsed}>
                      <Building2Icon className="h-4 w-4" />
                      {!isCollapsed && t("navigation.org")}
                    </DashLink>
                    <ShowWithPermissions minimun="ADMIN">
                      <DashLink href="/users" isCollapsed={isCollapsed}>
                        <UsersIcon className="h-4 w-4" />
                        {!isCollapsed && t("navigation.users")}
                      </DashLink>
                      {user?.org.is_activated && (
                        <PortalLink isCollapsed={isCollapsed} />
                      )}
                      <DashLink href="/settings" isCollapsed={isCollapsed}>
                        <SettingsIcon className="h-4 w-4" />
                        {!isCollapsed && t("navigation.settings")}
                      </DashLink>
                    </ShowWithPermissions>
                  </ShowWithPermissions>

                  <Separator className="my-2 bg-purple-500" />

                  <ShowWithPermissions minimun="SUPERADMIN">
                    <DashLink href="/superadmin" isCollapsed={isCollapsed}>
                      <ShieldIcon className="h-4 w-4" />
                      {!isCollapsed && t("navigation.superadmin")}
                    </DashLink>
                  </ShowWithPermissions>
                  <DashButton
                    onClick={() =>
                      changeLanguage(language === "en" ? "es" : "en")
                    }
                    isCollapsed={isCollapsed}
                  >
                    <GlobeIcon className="h-4 w-4" />
                    {!isCollapsed && t("changeLanguage")}
                  </DashButton>
                </nav>
              </div>
            </div>
            <footer className="flex flex-col items-center px-4 pb-4">
              <div
                className={`flex items-start gap-4 ${
                  isCollapsed ? "flex-col items-center" : ""
                }`}
              >
                <div className="relative inline-block">
                  <Avatar className="border w-12 h-12">
                    <AvatarImage src={user?.photo!} />
                    <AvatarFallback>
                      {user?.name![0].toUpperCase()}
                    </AvatarFallback>
                  </Avatar>
                  <Avatar className="border rounded h-6 w-6 absolute -bottom-1 -right-1">
                    <AvatarImage src={user?.org.photo!} />
                    <AvatarFallback>
                      {user?.org?.name[0].toUpperCase()}
                    </AvatarFallback>
                  </Avatar>
                </div>
                {!isCollapsed && (
                  <div className="flex flex-col">
                    <p className="font-semibold">{user?.name}</p>
                    <p className="text-sm text-purple-600">{user?.org.name}</p>
                  </div>
                )}
              </div>
              <Button className="mt-4 w-full" onClick={logout}>
                {isCollapsed ? (
                  <ExternalLinkIcon className="h-4 w-4" />
                ) : (
                  t("navigation.header.logout")
                )}
              </Button>
            </footer>
          </div>
        </aside>
        <div className="flex items-center">
          <Button
            variant="outline"
            size="icon"
            className="h-12 w-6 rounded-l-none p-0 flex items-center justify-center"
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            <div className="w-4 h-4 flex items-center justify-center">
              <img
                src="https://assets.pulpoo.com/pulpoo.png"
                alt="Toggle sidebar"
                className="max-w-full max-h-full object-contain"
              />
            </div>
          </Button>
        </div>
      </div>
      <main className="flex-1 flex flex-col overflow-auto p-4 md:px-1 md:py-6">
        {user?.org.is_freeze ? (
          <Freeze />
        ) : !user?.org.is_activated ? (
          <ActivateAccount />
        ) : (
          children
        )}
      </main>
    </div>
  );
}
