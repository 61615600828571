import { useCallback, useEffect, useRef, useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Maximize2, Minimize2, Loader } from 'lucide-react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LabelList } from 'recharts';
import { useTranslation } from 'react-i18next';
import { DateRange } from "react-day-picker";
import useSWR from 'swr';
import { fetcher } from '@/lib/fetcher'
import { startOfDay, endOfDay } from 'date-fns'
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "@/components/ui/select"
import { SortAscIcon, SortDescIcon, RowsIcon } from 'lucide-react'
import { ChartErrorBoundary } from "@/components/error/ChartErrorBoundary";

interface PriceChannelProps {
    channelPriceMetrics?: {
        totalPrice: number;
        byChannel: {
            [key: string]: {
                name: string;
                totalPrice: number;
                tasks: Array<{
                    id: string;
                    price: number;
                    title: string;
                    channel: {
                        id: string;
                        name: string;
                    };
                }>;
            };
        };
        pagination: {
            total: number;
            currentPage: number;
            pageSize: number;
            totalPages: number;
            hasMore: boolean;
        };
    };
    selectedProjects: string[];
    selectedPersons: string[];
    selectedRange?: DateRange;
    onTaskSelect: (tasks: string[]) => void;
}

// Custom tooltip component
const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    const totalSum = payload.reduce((sum: number, entry: any) => sum + entry.value, 0);

    return (
      <div style={{ maxHeight: '150px', overflowY: 'auto', backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
        <div><strong>{label}</strong></div>
        <div><strong>Total: ${totalSum.toFixed(0)}</strong></div>
        {payload.map((entry: any, index: number) => {
          const taskIndex = entry.name.replace('task', '');
          const taskName = entry.payload.taskNames[`task${taskIndex}`];
          return (
            <div key={`item-${index}`} style={{ color: entry.color }}>
              <strong>{taskName || entry.name}:</strong> ${entry.value.toFixed(0)}
            </div>
          );
        })}
      </div>
    );
  }
  return null;
};

export const PriceChannel: React.FC<PriceChannelProps> = ({ selectedProjects, selectedPersons, selectedRange, onTaskSelect }) => {
  const { t } = useTranslation();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);
  const [sortOrder, setSortOrder] = useState<'desc' | 'asc'>('desc');
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);

  const { data: channelPriceMetrics, error, isLoading } = useSWR(
    selectedProjects || selectedPersons || selectedRange ? 
    `/v1/metrics/channel-price-metrics?${new URLSearchParams({
      page: page.toString(),
      pageSize: pageSize.toString(),
      sort: sortOrder,
      ...(selectedPersons?.length > 0 && {
        people: JSON.stringify(selectedPersons),
      }),
      ...(selectedProjects?.length > 0 && {
        projects: JSON.stringify(selectedProjects),
      }),
      ...(selectedRange?.from && {
        from: String(startOfDay(selectedRange.from).getTime()),
      }),
      ...(selectedRange?.to && {
        to: String(endOfDay(selectedRange.to).getTime()),
      }),
    }).toString()}` : 
    `/v1/metrics/channel-price-metrics?page=${page}&pageSize=${pageSize}&sort=${sortOrder}`,
    fetcher
  );

  const toggleFullScreen = useCallback(() => {
    if (!document.fullscreenElement && cardRef.current) {
      cardRef.current.requestFullscreen().catch(err => {
        console.error(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
      });
    } else {
      document.exitFullscreen();
    }
  }, []);

  const handleFullScreenChange = useCallback(() => {
    setIsFullScreen(!!document.fullscreenElement);
  }, []);

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullScreenChange);
    return () => document.removeEventListener('fullscreenchange', handleFullScreenChange);
  }, [handleFullScreenChange]);

  if (isLoading) return <Loader className="h-6 w-6" />;
  if (error) return <div>Error loading data</div>;
  if (!channelPriceMetrics) return null;


  // Log transformed data
  const data = channelPriceMetrics ? Object.values(channelPriceMetrics.byChannel)
    .map((channel: typeof channelPriceMetrics.byChannel[keyof typeof channelPriceMetrics.byChannel]) => ({
      name: channel.name,
      totalPrice: channel.totalPrice,
      tasks: channel.tasks,
      taskNames: channel.tasks.reduce((acc: Record<string, string>, task: { title: string }, index: number) => ({
        ...acc,
        [`task${index}`]: task.title
      }), {}),
      ...channel.tasks.reduce((acc: Record<string, number>, task: { price: number }, index: number) => ({
        ...acc,
        [`task${index}`]: task.price
      }), {})
    }))
    .sort((a, b) => b.totalPrice - a.totalPrice) : [];


  const maxPrice = Math.max(...data.map(item => item.totalPrice));
  
  const numberOfTasks = Math.max(...data.map(channel => 
    channel.tasks?.length || 0
  ));

  const taskBars = Array.from({ length: numberOfTasks }, (_, index) => (
    <Bar
      key={`task${index}`}
      dataKey={`task${index}`}
      stackId="a"
      fill={`hsl(${index * 30}, 70%, 50%)`}
      radius={[0, 0, 0, 0]}
    />
  ));

  const handlePageSizeChange = (value: string) => {
    const newSize = parseInt(value);
    setPageSize(newSize);
    setPage(1);
  };

  const handleBarClick = (data: any) => {
    // Get all task IDs from the clicked channel's data
    const allTaskIds = data.tasks.map((task: any) => task.id);
    if (allTaskIds.length > 0) {
      onTaskSelect(allTaskIds);
    }
  };

  return (
    <div className="flex flex-col w-full">
      <div className="w-full mb-2 p-2 bg-gradient-to-r from-purple-100 to-purple-50 border border-purple-200 rounded-md shadow-sm">
        <div className="flex flex-wrap gap-2% items-center justify-start">
          <div className="flex items-center gap-1% min-w-[140px] w-[49%]">
            <RowsIcon className="w-[8%] h-auto text-purple-600 flex-shrink-0" />
            <Select
              value={pageSize.toString()}
              onValueChange={handlePageSizeChange}
            >
              <SelectTrigger className="w-[91%] h-8 bg-white border-purple-200 hover:border-purple-300 focus:ring-1 focus:ring-purple-300 text-[0.8em]">
                <SelectValue placeholder={t('metrics.charts.persons.selectRows')} />
              </SelectTrigger>
              <SelectContent 
                className="z-[9999]"
                position="popper" 
                sideOffset={4}
              >
                {[5, 10, 25, 50, 100].map((size) => (
                  <SelectItem key={size} value={size.toString()} className="text-[0.8em]">
                    {size} {t('metrics.charts.persons.rows')}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div className="flex items-center gap-1% min-w-[140px] w-[49%]">
            {sortOrder === 'desc' ? (
              <SortDescIcon className="w-[8%] h-auto text-purple-600 flex-shrink-0" />
            ) : (
              <SortAscIcon className="w-[8%] h-auto text-purple-600 flex-shrink-0" />
            )}
            <Select
              value={sortOrder}
              onValueChange={(value: 'desc' | 'asc') => setSortOrder(value)}
            >
              <SelectTrigger className="w-[91%] h-8 bg-white border-purple-200 hover:border-purple-300 focus:ring-1 focus:ring-purple-300 text-[0.8em]">
                <SelectValue placeholder={t('metrics.charts.persons.selectRows')} />
              </SelectTrigger>
              <SelectContent 
                className="z-[9999]"
                position="popper" 
                sideOffset={4}
              >
                <SelectItem value="desc" className="text-[0.8em]">
                  {t('metrics.charts.cost.highestCost')}
                </SelectItem>
                <SelectItem value="asc" className="text-[0.8em]">
                  {t('metrics.charts.cost.lowestCost')}
                </SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
      </div>

      <Card className={`w-full max-w-3xl ${isFullScreen ? 'h-screen' : ''}`} ref={cardRef}>
        <CardHeader>
          <div className="flex flex-row items-center justify-between">
            <div>
              <CardTitle>{t("metrics.charts.price_channel.title")}</CardTitle>
              <CardDescription>
                {t("metrics.total_cost.total_value", { 
                  value: channelPriceMetrics?.totalPrice 
                    ? `${channelPriceMetrics.totalPrice.toFixed(0)}` 
                    : '$0'
                })}
              </CardDescription>
            </div>
            <Button
              onClick={toggleFullScreen}
              variant="outline"
              size="icon"
            >
              {isFullScreen ? <Minimize2 className="h-4 w-4" /> : <Maximize2 className="h-4 w-4" />}
            </Button>
          </div>
        </CardHeader>
        <CardContent className={`${isFullScreen ? 'h-[calc(100vh-120px)]' : 'h-[400px]'} relative z-10`}>
          <ChartErrorBoundary>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart 
                data={data}
                layout="vertical"
                margin={{ top: 10, right: 0, bottom: 0, left: 10 }}
                className="relative z-20"
                onClick={(e) => {
                  if (e && e.activePayload) {
                    handleBarClick(e.activePayload[0].payload);
                  }
                }}
              >
                <LabelList
                  dataKey="totalPrice"
                  position="right"
                  formatter={(value: number) => `$${value.toFixed(0)}`}
                  style={{ fill: 'hsl(var(--foreground))', fontSize: '12px' }}
                />
                <CartesianGrid 
                  strokeDasharray="3 3" 
                  opacity={0.8}
                  stroke="#666"
                  vertical={true}
                  horizontal={true}
                  className="z-20"
                />
                <YAxis 
                  dataKey="name" 
                  type="category"
                  width={90}
                />
                <XAxis 
                  type="number"
                  domain={[0, maxPrice * 1.1]}
                  tickFormatter={(value) => `$${Math.round(value)}`}
                  allowDecimals={false}
                />
                <Tooltip 
                  content={<CustomTooltip />}
                  cursor={{ fill: 'rgba(0, 0, 0, 0.1)' }}
                />
                {taskBars}
              </BarChart>
            </ResponsiveContainer>
          </ChartErrorBoundary>
        </CardContent>
      </Card>
    </div>
  );
}

  