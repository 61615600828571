import { useState } from "react";
import { Checkbox } from "@/components/ui/checkbox";
import { useTaskSheet } from "@/lib/sheet";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import { ChevronsUpDown } from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Avatar, AvatarImage } from "@/components/ui/avatar";
import useSWR from "swr";
import { fetcher } from "@/lib/fetcher";
import { Task } from "@/types/task";
import { endOfMonth, startOfMonth } from "date-fns";
import { Switch } from "@/components/ui/switch";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

export function FiltersSelector() {
  const { t } = useTranslation();
  const { filters, setFilters } = useTaskSheet();
  const [userSwitches, setUserSwitches] = useState<Map<string, boolean>>(
    new Map()
  );

  const { data } = useSWR<{ tasks: Task[] }>(
    `/v1/dashboard/calendar/range/${startOfMonth(
      new Date()
    ).getTime()}/${endOfMonth(new Date()).getTime()}`,
    fetcher
  );

  const { data: metricsData } = useSWR<{
    projects: Array<{
      id: string;
      name: string;
      photo: string;
    }>;
  }>("/v1/metrics/payload", fetcher);

  const uniqueUsersMap = new Map();
  data?.tasks.forEach((task) => {
    if (task.assigned_to) {
      uniqueUsersMap.set(task.assigned_to.id, {
        id: task.assigned_to.id,
        name: task.assigned_to.name,
        avatar: task.assigned_to.photo,
      });
    }
  });

  const uniqueUsers = Array.from(uniqueUsersMap.values());

  return (
    <div className="flex items-center gap-4">
      <div className="flex items-center space-x-2">
        <Checkbox
          id="pending"
          checked={filters.pending}
          onCheckedChange={(checked) =>
            setFilters({ ...filters, pending: !!checked })
          }
        />
        <label
          htmlFor="pending"
          className="font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
        >
          {t("calendar.options.PENDING")}
        </label>
      </div>
      <div className="flex items-center space-x-2">
        <Checkbox
          id="inReview"
          checked={filters.inReview}
          onCheckedChange={(checked) =>
            setFilters({ ...filters, inReview: !!checked })
          }
        />
        <label
          htmlFor="inProgress"
          className="font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
        >
          {t("calendar.options.IN_REVIEW")}
        </label>
      </div>
      <div className="flex items-center space-x-2">
        <Checkbox
          id="inProgress"
          checked={filters.inProgress}
          onCheckedChange={(checked) =>
            setFilters({ ...filters, inProgress: !!checked })
          }
        />
        <label
          htmlFor="inProgress"
          className="font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
        >
          {t("calendar.options.IN_PROGRESS")}
        </label>
      </div>
      <div className="flex items-center space-x-2">
        <Checkbox
          id="done"
          checked={filters.done}
          onCheckedChange={(checked) =>
            setFilters({ ...filters, done: !!checked })
          }
        />
        <label
          htmlFor="done"
          className="font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
        >
          {t("calendar.options.DONE")}
        </label>
      </div>
      <Popover>
        <PopoverTrigger asChild className="w-[250px] p-2">
          <Button variant="outline" className="w-[200px] justify-between">
            {filters.selectedUsers.length === 0
              ? `${t("calendar.filters.filter_user")}`
              : `${filters.selectedUsers.length} ${t(
                  "calendar.filters.select_user"
                )}`}
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[250px] p-2">
          <div className="space-y-2">
            {uniqueUsers.map((user) => (
              <div
                key={user.id}
                className="flex items-center gap-2 p-2 hover:bg-accent rounded-md cursor-pointer"
              >
                <div className="flex items-center flex-1">
                  <Checkbox
                    checked={filters.selectedUsers.includes(user.id)}
                    onCheckedChange={() => {
                      setFilters({
                        ...filters,
                        selectedUsers: filters.selectedUsers.includes(user.id)
                          ? filters.selectedUsers.filter((id) => id !== user.id)
                          : [...filters.selectedUsers, user.id],
                      });
                    }}
                  />
                  <Avatar className="h-6 w-6 ml-2">
                    <AvatarImage src={user.avatar} alt={user.name} />
                  </Avatar>
                  <span className="ml-2">{user.name}</span>
                </div>

                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div className="flex items-center gap-2">
                        <Switch
                          className="h-4 w-8 scale-75"
                          checked={userSwitches.get(user.id) || false}
                          onCheckedChange={(checked) => {
                            // Actualizar el estado de los switches
                            const newSwitches = new Map(userSwitches);
                            newSwitches.set(user.id, checked);
                            setUserSwitches(newSwitches);

                            setFilters({
                              ...filters,
                              assignedByUsers: checked
                                ? [...(filters.assignedByUsers || []), user.id]
                                : (filters.assignedByUsers || []).filter(
                                    (id) => id !== user.id
                                  ),
                            });
                          }}
                          onClick={(e) => e.stopPropagation()}
                        />
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>{t("calendar.filters.delegated_tooltip")}</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
            ))}
          </div>
        </PopoverContent>
      </Popover>
      <Popover>
        <PopoverTrigger asChild className="w-[230px] p-2">
          <Button variant="outline" className="w-[200px] justify-between">
            {!filters.selectedProjects || filters.selectedProjects.length === 0
              ? `${t("calendar.filters.filter_project")}`
              : `${filters.selectedProjects.length} ${t(
                  "calendar.filters.select_project"
                )}`}
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[230px] p-2">
          <div className="space-y-2">
            {metricsData?.projects.map((project) => (
              <div
                key={project.id}
                className="flex items-center gap-2 p-2 hover:bg-accent rounded-md cursor-pointer"
                onClick={() => {
                  setFilters({
                    ...filters,
                    selectedProjects: filters.selectedProjects?.includes(
                      project.id
                    )
                      ? (filters.selectedProjects || []).filter(
                          (id) => id !== project.id
                        )
                      : [...(filters.selectedProjects || []), project.id],
                  });
                }}
              >
                <Checkbox
                  checked={
                    filters.selectedProjects?.includes(project.id) || false
                  }
                  className="pointer-events-none"
                />
                <Avatar className="h-6 w-6">
                  <AvatarImage src={project.photo} alt={project.name} />
                </Avatar>
                <span>{project.name}</span>
              </div>
            ))}
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}
