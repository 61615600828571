import { useAuth } from "@/lib/auth";
import { Table, TableBody, TableCell, TableRow } from "@/components/ui/table";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { fetcher } from "@/lib/fetcher";
import { FetchError } from "@/components/error";

function Row({ title, description }: { title: string; description: string }) {
  return (
    <TableRow className="border-b border-gray-100">
      <TableCell className="border-r border-gray-100 p-2 bg-gray-200">
        {title}
      </TableCell>
      <TableCell className="p-2">{description}</TableCell>
    </TableRow>
  );
}

export function MyOrg() {
  const { user } = useAuth();
  const { t } = useTranslation();

  const { data, error } = useSWR("/v1/org/stats", fetcher);

  if (error) return <FetchError />;

  return (
    <section className="flex flex-col items-center gap-4">
      <img
        src={user?.org.photo!}
        alt={`${user?.org.name}'s Logo`}
        className="h-40 w-40 rounded-full border"
      />
      <h1 className="scroll-m-20 pb-2 text-3xl font-semibold tracking-tight first:mt-0">
        {t("org.name", { name: user?.org.name })}
      </h1>
      <Table className="border max-w-xl rounded-xl m-auto">
        <TableBody>
          <Row title={t("org.id")} description={user?.org.id!} />
          <Row title={t("org.bio")} description={user?.org.bio!} />
          {data && <Row title={t("org.users")} description={data.users} />}
          <Row
            title={t("org.created")}
            description={new Date(user?.org.created_at!).toDateString()}
          />
        </TableBody>
      </Table>
    </section>
  );
}
