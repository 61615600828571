import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import { authedFetch } from "@/lib/fetcher";

export function ActivateAccount() {
  const { t } = useTranslation();

  async function activate() {
    const activate = await authedFetch("/v1/org/activate", {
      method: "POST",
    });

    const data = await activate.json();

    window.open(data.url, "_blank", "noreferrer");
  }

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <h2 className="scroll-m-20 pb-2 text-3xl font-semibold tracking-tight first:mt-0 mx-6">
        {t("activation.title")}
      </h2>
      <p className="leading-7 mb-4">{t("activation.description")}</p>
      <Button onClick={activate} size="lg">
        {t("activation.cta")}
      </Button>
    </div>
  );
}
