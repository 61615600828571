import { useState, useEffect } from "react";
import { format, formatDistanceToNow, isPast } from "date-fns";
import { useTranslation } from "react-i18next";
import { enUS, es } from "date-fns/locale";

export function useDeadline(date: Date, isDone: boolean = false): string {
  const { t } = useTranslation();

  const locale = t("lang") === "es" ? es : enUS;

  function calculate(date: Date) {
    if (isDone)
      return t("time.done", {
        time: format(date, "dd MMMM yyyy hh:mm a", { locale }),
      });

    if (isPast(date)) return t("time.dued");

    const timeDifference = formatDistanceToNow(date, {
      includeSeconds: true,
      locale,
    });

    const timeUnits = timeDifference.split(", ");

    return t("time.deadline", {
      deadline:
        timeUnits.length > 1
          ? `${timeUnits[0]}, ${timeUnits[1]}`
          : timeUnits[0],
    });
  }

  const [maxTimeMeasurement, setMaxTimeMeasurement] = useState<string>(
    calculate(date)
  );

  useEffect(() => {
    const intervalId = setInterval(
      () => setMaxTimeMeasurement(calculate(date)),
      1000
    );

    return () => clearInterval(intervalId);
  }, [date]);

  return maxTimeMeasurement;
}
