import { z } from "zod";

export const validator = z.object({
  title: z.string().min(1, { message: "tasks.create.form.title.error" }),
  description: z.string(),
  deadline: z
    .string()
    .refine((d) => new Date(d).getTime() > new Date().getTime(), {
      message: "tasks.create.form.deadline.error",
    }),
  assigned_to_id: z
    .array(
      z.string().min(1, { message: "tasks.create.form.assigned_in_id.error" })
    )
    .min(1, { message: "tasks.create.form.assigned_in_id.error" }),
  assigned_in_id: z
    .string()
    .min(1, { message: "tasks.create.form.assigned_in_id.error" }),
  attached_files: z.array(z.string()).optional(),
  written_ubication: z.string().optional(),
  lat: z.string().optional(),
  lng: z.string().optional(),
  needs_manager: z.boolean().optional(),
});
