import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
  CardContent,
} from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useTranslation } from "react-i18next";
import { baseURL } from "@/globals";
import { useToast } from "@/components/ui/use-toast";
import { useLocation } from "wouter";
import { UploadImage } from "@/components/upload-photo";

const joinSchema = z
  .object({
    verification: z.string(),
    name: z.string().min(1, { message: "auth.join.errors.nameRequired" }),
    photo: z
      .string()
      .url()
      .min(1, { message: "auth.join.errors.photoRequired" }),
    bio: z.string().min(1, { message: "auth.join.errors.bioRequired" }),
    user_name: z
      .string()
      .min(1, { message: "auth.join.errors.user.nameRequired" }),
    user_photo: z
      .string()
      .url()
      .min(1, { message: "auth.join.errors.user.photoRequired" }),
    user_caption: z
      .string()
      .min(1, { message: "auth.join.errors.user.captionRequired" }),
    email: z
      .string()
      .min(1, { message: "auth.join.errors.emailRequired" })
      .email({ message: "auth.join.errors.invalidEmail" }),
    password: z
      .string()
      .min(8, { message: "auth.join.errors.passwordLength" })
      .refine(
        (value) =>
          /[A-Z]/.test(value) && /[a-z]/.test(value) && /\d/.test(value),
        { message: "auth.join.errors.passwordFormat" }
      ),
    confirmPassword: z
      .string()
      .min(1, { message: "auth.join.errors.confirmPasswordRequired" }),
  })
  .refine((data) => data.password === data.confirmPassword, {
    path: ["confirmPassword"],
    message: "auth.join.errors.confirmPasswordMismatch",
  });

type Form = z.infer<typeof joinSchema>;

export function Join({ code }: { code: string }) {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const { toast } = useToast();

  const [, navigate] = useLocation();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    setValue,
    watch,
  } = useForm<Form>({
    resolver: zodResolver(joinSchema),
    defaultValues: {
      verification: code,
    },
  });

  const photoData = watch("photo");
  const userPhotoData = watch("user_photo");

  const onSubmit: SubmitHandler<Form> = async (data) => {
    try {
      const req = await fetch(`${baseURL}/v1/org/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          code: data.verification,
          name: data.name,
          photo: data.photo,
          bio: data.bio,
          email: data.email,
          password: data.password,
          user_name: data.user_name,
          user_photo: data.user_photo,
          user_caption: data.user_caption,
        }),
      });

      const res = req.json() as unknown as { message: string };

      if (req.status === 403) {
        if (res.message === "code") {
          setError("verification", { message: "auth.join.errors.invalidCode" });
        }
      }

      toast({
        title: t("auth.join.toast.title"),
        description: t("auth.join.toast.description"),
      });

      navigate("/");
    } catch (e) {
      setError("root", {
        message: "auth.join.errors.incorrectCredentials",
      });
    }
  };

  return (
    <div className="flex justify-center">
      <Card className="w-full max-w-2xl my-6">
        <CardHeader>
          <CardTitle>{t("auth.join.title")}</CardTitle>
          <CardDescription>{t("auth.join.description")}</CardDescription>
        </CardHeader>
        <CardContent>
          <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
            <Button
              className="w-full"
              type="button"
              onClick={() => changeLanguage(language === "en" ? "es" : "en")}
            >
              {t("changeLanguage")}
            </Button>

            <div className="space-y-1">
              <Label htmlFor="verification">
                {t("auth.join.verificationCode")}
              </Label>

              <p className="text-sm text-muted-foreground">
                {t("auth.join.verificationCodeExplanation")}
              </p>

              <Input
                id="verification"
                placeholder={t("auth.join.enterVerificationCode")}
                type="text"
                {...register("verification")}
                disabled
              />

              {errors.verification && (
                <p className="text-sm text-red-500">
                  {t(errors.verification.message || "generic.error")}
                </p>
              )}
            </div>

            <h2 className="scroll-m-20 text-xl font-semibold tracking-tight">
              {t("auth.join.steps.step1")}
            </h2>

            <div className="space-y-1">
              <Label htmlFor="photo">{t("auth.join.photo")}</Label>

              <p className="text-sm text-muted-foreground">
                {t("auth.join.photoExplanation")}
              </p>

              <UploadImage
                data={photoData}
                endpoint="new-org"
                authorization={code}
                onError={() =>
                  setError("photo", {
                    message: "auth.join.errors.photoUploadingFailed",
                  })
                }
                onChange={(value) => setValue("photo", value)}
              />

              {errors.photo && (
                <p className="text-sm text-red-500">
                  {t(errors.photo.message || "generic.error")}
                </p>
              )}
            </div>

            <div className="space-y-1">
              <Label htmlFor="name">{t("auth.join.name")}</Label>

              <p className="text-sm text-muted-foreground">
                {t("auth.join.nameExplanation")}
              </p>

              <Input
                id="name"
                placeholder={t("auth.join.enterName")}
                type="text"
                {...register("name")}
              />

              {errors.name && (
                <p className="text-sm text-red-500">
                  {t(errors.name.message || "generic.error")}
                </p>
              )}
            </div>

            <div className="space-y-1">
              <Label htmlFor="bio">{t("auth.join.bio")}</Label>

              <p className="text-sm text-muted-foreground">
                {t("auth.join.bioExplanation")}
              </p>

              <Textarea
                id="bio"
                placeholder={t("auth.join.enterBio")}
                {...register("bio")}
              />

              {errors.bio && (
                <p className="text-sm text-red-500">
                  {t(errors.bio.message || "generic.error")}
                </p>
              )}
            </div>

            <h2 className="scroll-m-20 text-xl font-semibold tracking-tight">
              {t("auth.join.steps.step2")}
            </h2>

            <div className="space-y-1">
              <Label htmlFor="user_photo">{t("auth.join.user.photo")}</Label>

              <p className="text-sm text-muted-foreground">
                {t("auth.join.user.photoExplanation")}
              </p>

              <UploadImage
                data={userPhotoData}
                endpoint="new-user"
                authorization={code}
                onError={() =>
                  setError("user_photo", {
                    message: "auth.join.errors.photoUploadingFailed",
                  })
                }
                onChange={(value) => setValue("user_photo", value)}
              />

              {errors.user_photo && (
                <p className="text-sm text-red-500">
                  {t(errors.user_photo.message || "generic.error")}
                </p>
              )}
            </div>

            <div className="space-y-1">
              <Label htmlFor="user_name">{t("auth.join.user.name")}</Label>

              <p className="text-sm text-muted-foreground">
                {t("auth.join.user.nameExplanation")}
              </p>

              <Input
                id="user_name"
                placeholder={t("auth.join.user.enterName")}
                type="text"
                {...register("user_name")}
              />

              {errors.user_name && (
                <p className="text-sm text-red-500">
                  {t(errors.user_name.message || "generic.error")}
                </p>
              )}
            </div>

            <div className="space-y-1">
              <Label htmlFor="user_caption">
                {t("auth.join.user.caption")}
              </Label>

              <p className="text-sm text-muted-foreground">
                {t("auth.join.user.captionExplanation")}
              </p>

              <Input
                id="user_caption"
                placeholder={t("auth.join.user.enterCaption")}
                type="text"
                {...register("user_caption")}
              />

              {errors.user_caption && (
                <p className="text-sm text-red-500">
                  {t(errors.user_caption.message || "generic.error")}
                </p>
              )}
            </div>

            <h2 className="scroll-m-20 text-xl font-semibold tracking-tight">
              {t("auth.join.steps.step3")}
            </h2>

            <div className="space-y-1">
              <Label htmlFor="email">{t("auth.join.email")}</Label>
              <Input
                id="email"
                placeholder={t("auth.join.enterEmail")}
                type="email"
                {...register("email")}
              />

              {errors.email && (
                <p className="text-sm text-red-500">
                  {t(errors.email.message || "generic.error")}
                </p>
              )}
            </div>
            <div className="space-y-1">
              <Label htmlFor="password">{t("auth.join.password")}</Label>
              <Input
                id="password"
                placeholder={t("auth.join.enterPassword")}
                type="password"
                autoComplete="new-password"
                {...register("password")}
              />

              {errors.password && (
                <p className="text-sm text-red-500">
                  {t(errors.password.message || "generic.error")}
                </p>
              )}
            </div>

            <div className="space-y-1">
              <Label htmlFor="confirmPassword">
                {t("auth.join.confirmPassword")}
              </Label>
              <Input
                id="confirmPassword"
                placeholder={t("auth.join.enterConfirmPassword")}
                type="password"
                autoComplete="new-password"
                {...register("confirmPassword")}
              />

              {errors.confirmPassword && (
                <p className="text-sm text-red-500">
                  {t(errors.confirmPassword.message || "generic.error")}
                </p>
              )}
            </div>

            {errors.root && (
              <p className="text-sm text-red-500">
                {t(errors.root.message || "generic.error")}
              </p>
            )}

            <Button className="w-full" type="submit" disabled={isSubmitting}>
              {t("auth.join.createOrg")}
            </Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}
