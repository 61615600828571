import { useCalendar } from "@/lib/calendar";
import { endOfWeek, format, isSameDay, startOfWeek } from "date-fns";
import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { fetcher } from "@/lib/fetcher";
import { FetchError } from "@/components/error";
import { Task } from "@/types/task";
import { useTaskSheet } from "@/lib/sheet";
import { Loader } from "../ui/loader";
import { TaskCounts } from "./ui/count";
import { TaskList } from "./ui/render";

export function WeekCalendar() {
  const { t } = useTranslation();
  const { weekday, calendarWeek, locale, today, moveDate, actualDate } =
    useCalendar();

  const { filters } = useTaskSheet();

  const { data, error } = useSWR<{ tasks: Task[] }>(
    `/v1/dashboard/calendar/range/${startOfWeek(
      actualDate
    ).getTime()}/${endOfWeek(actualDate).getTime()}`,
    fetcher
  );

  if (error) return <FetchError />;

  return (
    <section className="w-full h-full flex flex-col">
      <header className="my-4 flex items-center justify-between">
        <p className="font-bold text-4xl">
          {`${format(calendarWeek[0].date, "dd MMMM yyyy", {
            locale,
          })} - ${format(calendarWeek[6].date, "dd MMMM yyyy", { locale })}`}
        </p>
        <div className="flex items-center gap-2">
          <Button onClick={moveDate.prevWeek}>
            {t("calendar.move.week.prev")}
          </Button>
          <Button onClick={moveDate.today}>{t("calendar.move.today")}</Button>
          <Button onClick={moveDate.nextWeek}>
            {t("calendar.move.week.next")}
          </Button>
        </div>
      </header>
      <section className="h-full">
        <header className="flex gap-2 justify-between border-b border-gray-300 pb-2 mb-2">
          {weekday.map((day) => (
            <div
              key={day}
              className="w-full text-right pr-2 font-semibold text-gray-600"
            >
              <p>{day}</p>
            </div>
          ))}
        </header>
        <section className="grid grid-cols-7 gap-2 h-[90%]">
          {calendarWeek.map(({ day, date }, index) => (
            <article
              key={index}
              className={`internal-index-${index} p-2 rounded-lg border border-gray-100 bg-white text-black`}
            >
              <div className="w-full flex items-center">
                <p
                  className={`font-semibold text-2xl w-8 text-center ${
                    isSameDay(date, today.date)
                      ? "rounded-full bg-red-600 text-white"
                      : ""
                  }`}
                >
                  {day}
                </p>
                {!data ? (
                  <Loader className="h-6 w-6" />
                ) : (
                  <TaskCounts
                    tasks={data.tasks.filter((task) =>
                      isSameDay(new Date(task.deadline), date)
                    )}
                    filters={filters}
                  />
                )}
              </div>
              {data && (
                <TaskList
                  tasks={data.tasks.filter((task) =>
                    isSameDay(new Date(task.deadline), date)
                  )}
                  displayAsCalendar
                  filters={filters}
                />
              )}
            </article>
          ))}
        </section>
      </section>
    </section>
  );
}
