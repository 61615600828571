import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/components/ui/use-toast";
import { useAuth } from "@/lib/auth";
import { authedFetch } from "@/lib/fetcher";
import { zodResolver } from "@hookform/resolvers/zod";
import { ChangeEvent, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

const editSchema = z.object({
  name: z.string().min(1, { message: "settings.errors.nameRequired" }),
  photo: z.string().url().min(1, { message: "settings.errors.photoRequired" }),
  bio: z.string().min(1, { message: "settings.errors.bioRequired" }),
});

type Form = z.infer<typeof editSchema>;

export function Settings() {
  const { t } = useTranslation();
  const { user } = useAuth();

  const { toast } = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    setValue,
    watch,
  } = useForm<Form>({
    resolver: zodResolver(editSchema),
    defaultValues: {
      name: user?.org.name,
      bio: user?.org.bio,
      photo: user?.org.photo!,
    },
  });

  const photoData = watch("photo");

  const onSubmit: SubmitHandler<Form> = async (data) => {
    try {
      await authedFetch("/v1/org/edit", {
        method: "PUT",
        body: JSON.stringify({
          name: data.name,
          photo: data.photo,
          bio: data.bio,
        }),
      });

      toast({
        title: t("settings.toast"),
      });
    } catch (e) {
      toast({
        title: t("settings.toast_error"),
      });
    }
  };

  const [isPhotoUploading, setIsPhotoUploading] = useState(false);
  const photoRef = useRef<HTMLInputElement>(null);

  const onPhotoChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    setIsPhotoUploading(true);

    const file = e.target.files[0];

    const { type, name } = file;

    try {
      const payloadReq = await authedFetch("/v1/blob/org-picture", {
        method: "POST",
        body: JSON.stringify({
          type,
          ext: name.split(".").pop(),
        }),
      });

      const { signedUrl, finalUrl } = await payloadReq.json();

      const request = await fetch(signedUrl, {
        method: "PUT",
        headers: {
          "Content-Type": type,
        },
        body: file,
      });

      if (!request.ok) {
        setError("photo", { message: "settings.errors.photoUploadingFailed" });
      }

      setValue("photo", finalUrl);

      setIsPhotoUploading(false);
    } catch (e) {
      setError("photo", { message: "settings.errors.photoUploadingFailed" });
      setIsPhotoUploading(false);
    }
  };

  return (
    <section>
      <h1 className="scroll-m-20 text-3xl font-semibold tracking-tight first:mt-0 mb-8">
        {t("settings.title")}
      </h1>
      <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-1">
          <Label htmlFor="photo">{t("settings.photo")}</Label>

          {!photoData ? (
            <>
              <input
                id="photo"
                type="file"
                accept="image/*"
                onChange={onPhotoChange}
                className="hidden"
                ref={photoRef}
              />
              <Button
                disabled={isPhotoUploading}
                onClick={() => photoRef.current!.click()}
                type="button"
              >
                {t("settings.photoUpload")}
              </Button>
            </>
          ) : (
            <div>
              <img
                src={photoData}
                alt="Organization logo"
                className="w-40 rounded border my-2"
              />
              <Button onClick={() => setValue("photo", "")} type="button">
                {t("settings.changeImage")}
              </Button>
            </div>
          )}

          {errors.photo && (
            <p className="text-sm text-red-500">
              {t(errors.photo.message || "generic.error")}
            </p>
          )}
        </div>

        <div className="space-y-1">
          <Label htmlFor="name">{t("settings.name")}</Label>

          <Input
            id="name"
            placeholder={t("settings.enterName")}
            type="text"
            {...register("name")}
          />

          {errors.name && (
            <p className="text-sm text-red-500">
              {t(errors.name.message || "generic.error")}
            </p>
          )}
        </div>

        <div className="space-y-1">
          <Label htmlFor="bio">{t("settings.bio")}</Label>

          <Textarea
            id="bio"
            placeholder={t("settings.enterBio")}
            {...register("bio")}
          />

          {errors.bio && (
            <p className="text-sm text-red-500">
              {t(errors.bio.message || "generic.error")}
            </p>
          )}
        </div>

        <Button className="w-full" type="submit" disabled={isSubmitting}>
          {t("settings.edit")}
        </Button>
      </form>
    </section>
  );
}
