import { createContext, useContext, useState, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import {
  format,
  eachDayOfInterval,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  isSameMonth,
  Locale,
} from "date-fns";
import { enUS, es } from "date-fns/locale";

export const CalendarContext = createContext<{
  weekday: string[];
  moveDate: {
    nextDay: () => void;
    prevDay: () => void;
    nextWeek: () => void;
    prevWeek: () => void;
    nextMonth: () => void;
    prevMonth: () => void;
    today: () => void;
  };
  actualDate: Date;
  calendarMonth: {
    date: Date;
    isCurrentMonth: boolean;
    formattedDate: string;
    day: number;
    month: number;
    year: number;
  }[];
  calendarWeek: {
    date: Date;
    isCurrentMonth: boolean;
    formattedDate: string;
    day: number;
    month: number;
    year: number;
  }[];
  currentDay: {
    date: Date;
    formattedDate: string;
    day: number;
    month: number;
    year: number;
  };
  today: {
    date: Date;
    formattedDate: string;
    day: number;
    month: number;
    year: number;
  };
  locale: Locale;
}>({
  weekday: [],
  moveDate: {
    nextDay: () => {},
    prevDay: () => {},
    nextWeek: () => {},
    prevWeek: () => {},
    nextMonth: () => {},
    prevMonth: () => {},
    today: () => {},
  },
  actualDate: new Date(),
  calendarMonth: [],
  calendarWeek: [],
  currentDay: {
    date: new Date(),
    formattedDate: "",
    day: 0,
    month: 0,
    year: 0,
  },
  today: {
    date: new Date(),
    formattedDate: "",
    day: 0,
    month: 0,
    year: 0,
  },
  locale: es,
});

export function CalendarProvider({ children }: { children: ReactNode }) {
  const { t } = useTranslation();

  const [actualDate, setActualDate] = useState(new Date());

  function nextDay() {
    setActualDate((prev) => new Date(prev.getTime() + 24 * 60 * 60 * 1000));
  }

  function prevDay() {
    setActualDate((prev) => new Date(prev.getTime() - 24 * 60 * 60 * 1000));
  }

  function nextWeek() {
    setActualDate((prev) => new Date(prev.getTime() + 7 * 24 * 60 * 60 * 1000));
  }

  function prevWeek() {
    setActualDate((prev) => new Date(prev.getTime() - 7 * 24 * 60 * 60 * 1000));
  }

  function nextMonth() {
    setActualDate((prev) => {
      const date = new Date(prev);
      date.setMonth(date.getMonth() + 1);
      return date;
    });
  }

  function prevMonth() {
    setActualDate((prev) => {
      const date = new Date(prev);
      date.setMonth(date.getMonth() - 1);
      return date;
    });
  }

  const moveDate = {
    nextDay,
    prevDay,
    nextWeek,
    prevWeek,
    nextMonth,
    prevMonth,
    today: () => {
      setActualDate(new Date());
    },
  };

  const weekday = {
    en: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    es: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
  };

  function generateCalendarForMonth(date: Date, locale: Locale) {
    const start = startOfWeek(startOfMonth(date));
    const end = endOfWeek(endOfMonth(date));
    const days = eachDayOfInterval({ start, end });

    return days.map((day) => ({
      date: day,
      isCurrentMonth: isSameMonth(day, date),
      formattedDate: format(day, "PPP", { locale }),
      day: day.getDate(),
      month: day.getMonth() + 1,
      year: day.getFullYear(),
    }));
  }

  function generateCalendarForWeek(date: Date, locale: Locale) {
    const start = startOfWeek(date);
    const end = endOfWeek(date);
    const days = eachDayOfInterval({ start, end });

    return days.map((day) => ({
      date: day,
      isCurrentMonth: isSameMonth(day, date),
      formattedDate: format(day, "PPP", { locale }),
      day: day.getDate(),
      month: day.getMonth() + 1,
      year: day.getFullYear(),
    }));
  }

  function getCurrentDay(date: Date, locale: Locale) {
    return {
      date,
      formattedDate: format(date, "PPP", { locale }),
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };
  }

  const locale = t("lang") === "es" ? es : enUS;

  const calendarMonth = generateCalendarForMonth(actualDate, locale);
  const calendarWeek = generateCalendarForWeek(actualDate, locale);
  const currentDay = getCurrentDay(actualDate, locale);
  const today = getCurrentDay(new Date(), locale);

  const calendarData = {
    weekday: weekday[t("lang") as "en" | "es"],
    moveDate,
    actualDate,
    calendarMonth,
    calendarWeek,
    currentDay,
    today,
    locale,
  };

  return (
    <CalendarContext.Provider value={calendarData}>
      {children}
    </CalendarContext.Provider>
  );
}

export const useCalendar = () => useContext(CalendarContext);
