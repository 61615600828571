import { useTranslation } from "react-i18next";

export function FetchError() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <h2 className="scroll-m-20 pb-2 text-3xl font-semibold tracking-tight first:mt-0 mx-6">
        {t("generic.fetch.title")}
      </h2>
      <p className="leading-7 mb-4">{t("generic.fetch.description")}</p>
    </div>
  );
}
