import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useTranslation } from "react-i18next";
import { authedFetch } from "@/lib/fetcher";
import { toast } from "@/components/ui/use-toast";

const requestSchema = z.object({
  email: z
    .string()
    .min(1, { message: "auth.reset.errors.emailRequired" })
    .email({ message: "auth.reset.errors.invalidEmail" }),
});

const changeSchema = z
  .object({
    code: z.string(),
    password: z
      .string()
      .min(8, { message: "auth.reset.errors.passwordLength" })
      .refine(
        (value) =>
          /[A-Z]/.test(value) && /[a-z]/.test(value) && /\d/.test(value),
        { message: "auth.reset.errors.passwordFormat" }
      ),
    repeat_password: z.string(),
  })
  .refine((data) => data.password === data.repeat_password, {
    path: ["repeat_password"],
    message: "auth.reset.errors.confirmPasswordMismatch",
  });

function RequestResetPassword() {
  const { t } = useTranslation();

  type Form = z.infer<typeof requestSchema>;

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<Form>({
    resolver: zodResolver(requestSchema),
  });

  const onSubmit: SubmitHandler<Form> = async (data) => {
    const req = await authedFetch("/v1/auth/reset-password", {
      method: "POST",
      body: JSON.stringify({
        email: data.email,
        lang: t("lang"),
      }),
    });

    if (req.ok) {
      toast({ title: t("auth.reset.request.toast") });
    } else {
      toast({ title: t("auth.reset.request.toast_error") });
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen">
      <Card className="w-[400px] my-6">
        <CardHeader>
          <CardTitle>{t("auth.reset.request.title")}</CardTitle>
        </CardHeader>
        <CardContent>
          <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-1">
              <Label htmlFor="email">{t("auth.reset.request.email")}</Label>
              <Input
                id="email"
                placeholder={t("auth.reset.request.enterEmail")}
                type="email"
                {...register("email")}
              />

              {errors.email && (
                <p className="text-sm text-red-500">
                  {t(errors.email.message || "generic.error")}
                </p>
              )}
            </div>

            {errors.root && (
              <p className="text-sm text-red-500">
                {t(errors.root.message || "generic.error")}
              </p>
            )}

            <Button className="w-full" type="submit" disabled={isSubmitting}>
              {t("auth.reset.request.action")}
            </Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}

function ResetPassword() {
  const { t } = useTranslation();

  type Form = z.infer<typeof changeSchema>;

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<Form>({
    resolver: zodResolver(changeSchema),
  });

  const onSubmit: SubmitHandler<Form> = async (data) => {
    const req = await authedFetch(`/v1/auth/reset-password/${data.code}`, {
      method: "POST",
      body: JSON.stringify({
        password: data.password,
        lang: t("lang"),
      }),
    });

    if (req.ok) {
      toast({ title: t("auth.reset.toast") });
    } else {
      toast({ title: t("auth.reset.toast_error") });
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen">
      <Card className="w-[400px] my-6">
        <CardHeader>
          <CardTitle>{t("auth.reset.title")}</CardTitle>
        </CardHeader>
        <CardContent>
          <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-1">
              <Label htmlFor="code">{t("auth.reset.code")}</Label>
              <Input
                id="code"
                placeholder={t("auth.reset.enterCode")}
                type="text"
                {...register("code")}
              />

              {errors.code && (
                <p className="text-sm text-red-500">
                  {t(errors.code.message || "generic.error")}
                </p>
              )}
            </div>
            <div className="space-y-1">
              <Label htmlFor="password">{t("auth.reset.password")}</Label>
              <Input
                id="password"
                placeholder={t("auth.reset.enterPassword")}
                type="password"
                {...register("password")}
              />

              {errors.password && (
                <p className="text-sm text-red-500">
                  {t(errors.password.message || "generic.error")}
                </p>
              )}
            </div>

            <div className="space-y-1">
              <Label htmlFor="repeatPassword">
                {t("auth.reset.repeatPassword")}
              </Label>
              <Input
                id="password"
                placeholder={t("auth.reset.enterRepeatPassword")}
                type="password"
                {...register("repeat_password")}
              />

              {errors.repeat_password && (
                <p className="text-sm text-red-500">
                  {t(errors.repeat_password.message || "generic.error")}
                </p>
              )}
            </div>

            {errors.root && (
              <p className="text-sm text-red-500">
                {t(errors.root.message || "generic.error")}
              </p>
            )}

            <Button className="w-full" type="submit" disabled={isSubmitting}>
              {t("auth.reset.action")}
            </Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}

export function ResetPasswordPage() {
  return (
    <section className="flex items-center justify-center gap-12 w-full">
      <RequestResetPassword />
      <ResetPassword />
    </section>
  );
}
