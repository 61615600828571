import { DayCalendar } from "@/components/calendar/day";
import { MonthCalendar } from "@/components/calendar/month";
import { WeekCalendar } from "@/components/calendar/week";
import { FiltersSelector } from "@/components/sheet/filters-selector";
import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import { Link, Route, Switch, useLocation } from "wouter";

export function Calendar() {
  const { t } = useTranslation();

  const [location] = useLocation();

  return (
    <section className="h-full max-h-full">
      <header className="flex items-center justify-between gap-4">
        <FiltersSelector />
        <div className="flex items-center gap-4">
          <Link href="/" asChild>
            <Button
              variant={
                !(location.startsWith("/week") || location.startsWith("/day"))
                  ? "default"
                  : "secondary"
              }
            >
              {t("calendar.options.month")}
            </Button>
          </Link>
          <Link href="/week" asChild>
            <Button
              variant={location.startsWith("/week") ? "default" : "secondary"}
            >
              {t("calendar.options.week")}
            </Button>
          </Link>
          <Link href="/day" asChild>
            <Button
              variant={location.startsWith("/day") ? "default" : "secondary"}
            >
              {t("calendar.options.day")}
            </Button>
          </Link>
        </div>
      </header>
      <Switch>
        <Route path="/">
          <MonthCalendar />
        </Route>
        <Route path="/week">
          <WeekCalendar />
        </Route>
        <Route path="/day">
          <DayCalendar />
        </Route>
      </Switch>
    </section>
  );
}
