import { FetchError } from "@/components/error";
import { TaskCard } from "@/components/task";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Loader } from "@/components/ui/loader";
import { useTimePeriod } from "@/hooks/time-period";
import { useAuth } from "@/lib/auth";
import { fetcher } from "@/lib/fetcher";
import { useTaskSheet } from "@/lib/sheet";
import { Task } from "@/types/task";
import { isToday } from "date-fns";
import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

function InnerTasks({
  mine,
  selected,
}: {
  mine: boolean;
  selected: "today" | "open" | "all";
}) {
  const { t } = useTranslation();
  const { controlSheet } = useTaskSheet();

  const { data, error } = useSWR<{ tasks: Task[] }>(
    `/v1/task/${mine ? "by-" : ""}me`,
    fetcher
  );

  if (!data) return <Loader />;

  if (error) return <FetchError />;

  const calculatedTask = data.tasks.filter((task) => {
    if (selected === "today" && !isToday(new Date(task.deadline))) return false;
    if (selected === "open" && task.status.startsWith("DONE")) return false;

    return true;
  });
  return (
    <>
      {calculatedTask.length < 1 ? (
        <p>{t("generic.no-tasks")}</p>
      ) : (
        calculatedTask.map((task, i) => (
          <TaskCard
            task={task}
            detail="user"
            scope={`${mine ? "by-" : ""}me`}
            onClick={() => controlSheet.openWithTask(task.id)}
            key={i}
          />
        ))
      )}
    </>
  );
}

function MyTasks() {
  const { t } = useTranslation();

  const [mine, setMine] = useState(false);

  const [selected, setSelected] = useState<"today" | "open" | "all">("today");

  const handleTodayClick = useCallback(() => setSelected("today"), []);
  const handleOpenClick = useCallback(() => setSelected("open"), []);
  const handleAllClick = useCallback(() => setSelected("all"), []);

  return (
    <section className="my-4 rounded-xl">
      <header className="flex items-center gap-4 my-4">
        <h2 className="scroll-m-20 text-2xl font-semibold tracking-tight">
          {t("today.title")}
        </h2>
        <nav className="flex items-center gap-4">
          <Button
            variant={selected === "today" ? "default" : "secondary"}
            onClick={handleTodayClick}
          >
            {t("today.selector.today")}
          </Button>
          <Button
            variant={selected === "open" ? "default" : "secondary"}
            onClick={handleOpenClick}
          >
            {t("today.selector.open")}
          </Button>
          <Button
            variant={selected === "all" ? "default" : "secondary"}
            onClick={handleAllClick}
          >
            {t("today.selector.all")}
          </Button>
          <div className="flex items-center space-x-2">
            <Checkbox
              id="mine"
              checked={mine}
              onCheckedChange={(checked) => setMine(!!checked)}
            />
            <label
              htmlFor="mine"
              className="font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              {t("today.selector.mine")}
            </label>
          </div>
        </nav>
      </header>
      <InnerTasks mine={mine} selected={selected} />
    </section>
  );
}

export function Home() {
  const { user } = useAuth();

  const timePeriod = useTimePeriod();

  const { t } = useTranslation();

  return (
    <main>
      <h1 className="scroll-m-20 text-3xl font-semibold tracking-tight first:mt-0">
        {t(`home.title.${timePeriod}`, { name: user?.name })}
      </h1>
      <MyTasks />
    </main>
  );
}
