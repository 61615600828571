import { Button } from "@/components/ui/button";
import { XIcon } from "lucide-react";
import { useTranslation } from "react-i18next";
import { Link } from "wouter";

export function StripeCancel() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center w-full h-full min-h-screen">
      <XIcon size="64" />
      <h2 className="scroll-m-20 pb-2 text-3xl font-semibold tracking-tight first:mt-0 mx-6">
        {t("stripe.cancel.title")}
      </h2>
      <p className="leading-7 mb-4">{t("stripe.cancel.description")}</p>
      <Link href="/">
        <Button>{t("stripe.signin")}</Button>
      </Link>
    </div>
  );
}
