import { FetchError } from "@/components/error";
import { Loading } from "@/components/loading";
import { Input } from "@/components/ui/input";
import { fetcher } from "@/lib/fetcher";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { Link, Route, Switch } from "wouter";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Separator } from "@/components/ui/separator";
import { UsersIcon } from "lucide-react";
import { FiltersSelector } from "@/components/sheet/filters-selector";
import { useTaskSheet } from "@/lib/sheet";
import { TaskCard } from "@/components/task";
import { Task } from "@/types/task";

interface EmployeeData {
  id: string;
  name: string;
  caption: string | null;
  photo: string | null;
}

interface TaskInfo {
  DONE: Task[];
  IN_PROGRESS: Task[];
  IN_REVIEW: Task[];
  PENDING: Task[];
}

function NoEmployee() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center h-full w-full">
      <UsersIcon size={64} />
      <p className="scroll-m-20 pt-2 text-3xl font-semibold tracking-tight first:mt-0">
        {t("employees.no_employee")}
      </p>
    </div>
  );
}

function EmployeeData({ id }: { id: string }) {
  const { t } = useTranslation();

  const { filters, controlSheet } = useTaskSheet();

  const { data, error } = useSWR<{
    employee: EmployeeData;
    taskInfo: TaskInfo;
  }>(`/v1/dashboard/employees/${id}`, fetcher);

  if (error) return <FetchError />;

  if (!data) return <Loading />;

  const { employee, taskInfo } = data;

  return (
    <article className="pl-4 overflow-x-scroll">
      <div className="flex items-center gap-4">
        <Avatar className="w-20 h-20">
          <AvatarImage src={employee.photo!} />
          <AvatarFallback>{employee.name[0].toUpperCase()}</AvatarFallback>
        </Avatar>
        <div className="flex flex-col">
          <h3 className="text-3xl font-semibold tracking-tight">
            {employee.name}
          </h3>
          {employee.caption && <p className="text-lg">{employee.caption}</p>}
          <p>
            {t("employees.profile.stats", {
              completed: taskInfo.DONE.length,
              progress: taskInfo.IN_PROGRESS.length,
              review: taskInfo.IN_REVIEW.length,
              pending: taskInfo.PENDING.length,
              total:
                taskInfo.DONE.length +
                taskInfo.IN_PROGRESS.length +
                taskInfo.IN_REVIEW.length +
                taskInfo.PENDING.length,
            })}
          </p>
        </div>
      </div>
      <h3 className="my-6 text-3xl font-semibold tracking-tight first:mt-0">
        {t("employees.profile.open")}
      </h3>
      <div>
        <FiltersSelector />
        <div className="mt-4">
          {[
            ...[filters.pending && "PENDING"],
            ...[filters.inProgress && "IN_PROGRESS"],
            ...[filters.inReview && "IN_REVIEW"],
            ...[filters.done && "DONE"],
          ].map((status) =>
            taskInfo[status as keyof TaskInfo]?.map((task, idx) => {
              return (
                <TaskCard
                  key={idx}
                  task={task}
                  detail="title"
                  onClick={() => controlSheet.openWithTask(task.id)}
                />
              );
            })
          )}
        </div>
      </div>
    </article>
  );
}

export function Employees() {
  const { t } = useTranslation();

  const [search, setSearch] = useState<string>("");

  const { data, error } = useSWR<{ employees: EmployeeData[] }>(
    "/v1/dashboard/employees",
    fetcher
  );

  if (error) return <FetchError />;

  if (!data) return <Loading />;

  const { employees } = data;

  const employeesData = employees
    .filter((employee) =>
      employee.name.toLowerCase().includes(search.toLowerCase())
    )
    .sort((a, b) => a.name.localeCompare(b.name))
    .reduce((acc, employee) => {
      const firstLetter = employee.name[0].toUpperCase();

      if (!acc[firstLetter]) {
        acc[firstLetter] = [];
      }

      acc[firstLetter].push(employee);

      return acc;
    }, {} as Record<string, EmployeeData[]>);

  return (
    <section className="flex justify-between h-full max-h-full">
      <div className="w-1/3 h-full overflow-y-scroll">
        <Input
          placeholder={t("employees.search.placeholder")}
          value={search}
          onChange={(val) => setSearch(val.target.value)}
        />
        <div className="flex flex-col gap-2">
          {Object.entries(employeesData).map(([letter, employees]) => (
            <div key={letter} className="mt-4">
              <h2 className="scroll-m-20 border-b pb-2 text-3xl font-semibold tracking-tight first:mt-0">
                {letter}
              </h2>
              <Separator />
              <ul>
                {employees.map((employee) => (
                  <Link
                    key={employee.id}
                    href={`/${employee.id}`}
                    className="flex items-center gap-2 p-2 hover:bg-gray-100 dark:hover:bg-gray-800/40 rounded-md transition-colors"
                  >
                    <Avatar className="w-12 h-12">
                      <AvatarImage src={employee.photo!} />
                      <AvatarFallback>{letter}</AvatarFallback>
                    </Avatar>

                    <p className="font-semibold text-lg">{`${employee.name}${
                      employee.caption && ` (${employee.caption})`
                    }`}</p>
                  </Link>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <div className="w-2/3 h-full border-l">
        <Switch>
          <Route path="/:id">{({ id }) => <EmployeeData id={id} />}</Route>
          <Route path="/">
            <NoEmployee />
          </Route>
        </Switch>
      </div>
    </section>
  );
}
