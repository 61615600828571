import { useMemo } from "react";

export function useTimePeriod() {
  const now = new Date();
  const hour = now.getHours();

  const period = useMemo(() => {
    if (hour < 6) return "early";
    if (hour < 12) return "morning";
    if (hour < 18) return "afternoon";
    return "evening";
  }, [hour]);

  return period;
}
