import { baseURL } from "@/globals";

export async function fetcher(url: string) {
  const res = await fetch(`${baseURL}${url}`, {
    credentials: "include",
  });

  if (!res.ok) {
    const error: any = new Error("An error occurred while fetching the data.");
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }

  return await res.json();
}

export async function authedFetch(url: string, options: RequestInit) {
  const res = await fetch(`${baseURL}${url}`, {
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    ...options,
  });

  return res;
}
