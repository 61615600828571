import { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis, Tooltip, CartesianGrid } from "recharts"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card"
import { Button } from "@/components/ui/button";
import { useTranslation } from 'react-i18next';
import { Minimize2, Maximize2 } from "lucide-react"
import { Loader } from "@/components/ui/loader"
import  useSWR  from 'swr'
import { fetcher } from '@/lib/fetcher'
import { startOfDay, endOfDay } from 'date-fns'
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "@/components/ui/select"
import { SortAscIcon, SortDescIcon, RowsIcon } from 'lucide-react'
import { ChartErrorBoundary } from "@/components/error/ChartErrorBoundary";


interface TotalCostProps {
  priceMetrics: {
    totalPrice: number;
    selectedProjects: string[];
    selectedPersons: string[];
    selectedRange?: {
      from?: Date;
      to?: Date;
    };
    byAssignedTo: {
      [key: string]: {
        name: string;
        totalPrice: number;
        tasks: Array<{
          id: string;
          price: number;
          title: string;
          channel: {
            id: string;
            name: string;
          };
        }>;
      };
    };
    byAssignedBy: {
      [key: string]: {
        name: string;
        totalPrice: number;
        tasks: Array<{
          id: string;
          price: number;
          title: string;
          channel: {
            id: string;
            name: string;
          };
        }>;
      };
    };
  };
  onTaskSelect: (tasks: any[]) => void;
}

// Custom tooltip component
const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    const totalSum = payload.reduce((sum: number, entry: any) => sum + entry.value, 0);

    return (
      <div style={{ maxHeight: '150px', overflowY: 'auto', backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
        <div><strong>{label}</strong></div>
        <div><strong>Total: ${totalSum.toFixed(0)}</strong></div>
        {payload.map((entry: any, index: number) => {
          const taskIndex = entry.name.replace('task', '');
          const taskName = entry.payload.taskNames[`task${taskIndex}`];
          return (
            <div key={`item-${index}`} style={{ color: entry.color }}>
              <strong>{taskName || entry.name}:</strong> ${entry.value.toFixed(0)}
            </div>
          );
        })}
      </div>
    );
  }
  return null;
};

export const TotalCost = ({ priceMetrics, onTaskSelect }: TotalCostProps) => {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState<'byAssignedTo' | 'byAssignedBy'>('byAssignedTo');
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [sortOrder, setSortOrder] = useState<'desc' | 'asc'>('desc');
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const cardRef = useRef<HTMLDivElement>(null);

  const defaultMetrics = {
    ...priceMetrics,
    selectedProjects: priceMetrics?.selectedProjects || [],
    selectedPersons: priceMetrics?.selectedPersons || [],
    selectedRange: priceMetrics?.selectedRange
  };

  const {
    data: priceMetricsData,
    error,
    isLoading
  } = useSWR<{
    totalPrice: number;
    byAssignedTo: Record<string, {
      name: string;
      totalPrice: number;
      tasks: Array<{
        id: string;
        price: number;
        title: string;
        channel: {
          id: string;
          name: string;
        }
      }>;
    }>;
    byAssignedBy: Record<string, {
      name: string;
      totalPrice: number;
      tasks: Array<{
        id: string;
        price: number;
        title: string;
        channel: {
          id: string;
          name: string;
        }
      }>;
    }>;
    pagination: {
      byAssignedTo: {
        total: number;
        currentPage: number;
        pageSize: number;
        totalPages: number;
        hasMore: boolean;
      };
      byAssignedBy: {
        total: number;
        currentPage: number;
        pageSize: number;
        totalPages: number;
        hasMore: boolean;
      };
    };
  }>(
    `/v1/metrics/price-metrics${
      defaultMetrics.selectedProjects.length > 0 ||
      defaultMetrics.selectedPersons.length > 0 ||
      (defaultMetrics.selectedRange && (defaultMetrics.selectedRange.from || defaultMetrics.selectedRange.to))
        ? `?${new URLSearchParams({
            page: page.toString(),
            pageSize: pageSize.toString(),
            sort: sortOrder,
            view: dataSource,
            ...(defaultMetrics.selectedPersons.length > 0 && {
              people: JSON.stringify(defaultMetrics.selectedPersons),
            }),
            ...(defaultMetrics.selectedProjects.length > 0 && {
              projects: JSON.stringify(defaultMetrics.selectedProjects),
            }),
            ...(defaultMetrics.selectedRange &&
              defaultMetrics.selectedRange.from && {
                from: String(startOfDay(defaultMetrics.selectedRange.from).getTime()),
              }),
            ...(defaultMetrics.selectedRange &&
              defaultMetrics.selectedRange.to && {
                to: String(endOfDay(defaultMetrics.selectedRange.to).getTime()),
              }),
        }).toString()}`
        : `?page=${page}&pageSize=${pageSize}&sort=${sortOrder}&view=${dataSource}`
    }`,
    fetcher
  );

  const data = useMemo(() => {
    if (!priceMetricsData?.[dataSource]) return [];
    
    type PersonData = {
      name: string;
      totalPrice: number;
      tasks: Array<{
        id: string;
        price: number;
        title: string;
        channel: {
          id: string;
          name: string;
        }
      }>;
    }

    const entries = Object.values(priceMetricsData[dataSource] as Record<string, PersonData>)
      .map((person) => ({
        name: person.name,
        totalPrice: person.totalPrice,
        tasks: person.tasks,
        taskNames: person.tasks.reduce((acc, task, index) => ({
          ...acc,
          [`task${index}`]: task.title
        }), {}),
        ...person.tasks.reduce((acc, task, index) => ({
          ...acc,
          [`task${index}`]: task.price
        }), {})
      }));

    // Sort the data based on totalPrice
    return entries.sort((a, b) => {
      if (sortOrder === 'asc') {
        return a.totalPrice - b.totalPrice;
      }
      return b.totalPrice - a.totalPrice;
    });
  }, [priceMetricsData, dataSource, sortOrder]);

  
  const numberOfTasks = Math.max(...data.map(person => 
    person.tasks?.length || 0
  ));

  const taskBars = Array.from({ length: numberOfTasks }, (_, index) => (
    <Bar
      key={`task${index}`}
      dataKey={`task${index}`}
      stackId="a"
      fill={`hsl(${index * 30}, 70%, 50%)`}
      radius={[0, 0, 0, 0]}
    />
  ));

  const toggleFullScreen = useCallback(() => {
    if (!document.fullscreenElement && cardRef.current) {
      cardRef.current.requestFullscreen().catch(err => {
        console.error(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
      });
    } else {
      document.exitFullscreen();
    }
  }, []);

  const handleFullScreenChange = useCallback(() => {
    setIsFullScreen(!!document.fullscreenElement);
  }, []);

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullScreenChange);
    return () => document.removeEventListener('fullscreenchange', handleFullScreenChange);
  }, [handleFullScreenChange]);

  const handlePageSizeChange = (value: string) => {
    const newSize = parseInt(value);
    setPageSize(newSize);
    setPage(1);
  };

  const handleDataSourceChange = useCallback(() => {
    setDataSource(prev => prev === 'byAssignedTo' ? 'byAssignedBy' : 'byAssignedTo');
    // Reset page when changing view
    setPage(1);
  }, []);

  const handleBarClick = (data: any) => {
    // Get all task IDs from the clicked person's data
    const allTaskIds = data.tasks.map((task: any) => task.id);
    if (allTaskIds.length > 0) {
      onTaskSelect(allTaskIds);
    }
  };

  if (isLoading) return <Loader className="h-6 w-6" />;
  if (error) return <div>Error loading data</div>;
  if (!priceMetricsData) return null;

  return (
    <div className="flex flex-col w-full">
      <div className="w-full mb-2 p-2 bg-gradient-to-r from-purple-100 to-purple-50 border border-purple-200 rounded-md shadow-sm">
        <div className="flex flex-wrap gap-2% items-center justify-start">
          <div className="flex items-center gap-1% min-w-[140px] w-[49%]">
            <RowsIcon className="w-[8%] h-auto text-purple-600 flex-shrink-0" />
            <Select
              value={pageSize.toString()}
              onValueChange={handlePageSizeChange}
            >
              <SelectTrigger className="w-[91%] h-8 bg-white border-purple-200 hover:border-purple-300 focus:ring-1 focus:ring-purple-300 text-[0.8em]">
                <SelectValue placeholder={t('metrics.charts.persons.selectRows')} />
              </SelectTrigger>
              <SelectContent 
                className="z-[9999]"
                position="popper" 
                sideOffset={4}
              >
                {[5, 10, 25, 50, 100].map((size) => (
                  <SelectItem key={size} value={size.toString()} className="text-[0.8em]">
                    {size} {t('metrics.charts.persons.rows')}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div className="flex items-center gap-1% min-w-[140px] w-[49%]">
            {sortOrder === 'desc' ? (
              <SortDescIcon className="w-[8%] h-auto text-purple-600 flex-shrink-0" />
            ) : (
              <SortAscIcon className="w-[8%] h-auto text-purple-600 flex-shrink-0" />
            )}
            <Select
              value={sortOrder}
              onValueChange={(value: 'desc' | 'asc') => setSortOrder(value)}
            >
              <SelectTrigger className="w-[91%] h-8 bg-white border-purple-200 hover:border-purple-300 focus:ring-1 focus:ring-purple-300 text-[0.8em]">
                <SelectValue placeholder={t('metrics.charts.persons.selectRows')} />
              </SelectTrigger>
              <SelectContent 
                className="z-[9999]"
                position="popper" 
                sideOffset={4}
              >
                <SelectItem value="desc" className="text-[0.8em]">
                  {t('metrics.charts.cost.highestCost')}
                </SelectItem>
                <SelectItem value="asc" className="text-[0.8em]">
                  {t('metrics.charts.cost.lowestCost')}
                </SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
      </div>

      <Card className={`w-full max-w-3xl relative ${isFullScreen ? 'h-screen max-w-none' : ''}`} ref={cardRef}>
        <CardHeader className={isFullScreen ? 'pb-2' : ''}>
          <div className="flex flex-col gap-2 w-full">
            <div className="flex justify-between items-start">
              <div>
                <CardTitle>
                  {dataSource === 'byAssignedTo' 
                    ? t("metrics.total_cost.assignee") 
                    : t("metrics.total_cost.assigner")}
                </CardTitle>
                <CardDescription>
                  {t("metrics.total_cost.total_value", { 
                    value: priceMetricsData.totalPrice.toFixed(0) 
                  })}
                </CardDescription>
              </div>
              <Button
                onClick={toggleFullScreen}
                variant="outline"
                size="icon"
              >
                {isFullScreen ? <Minimize2 className="h-4 w-4" /> : <Maximize2 className="h-4 w-4" />}
              </Button>
            </div>
            <Button
              onClick={handleDataSourceChange}
              variant="outline"
              className="text-sm w-full"
            >
              {dataSource === 'byAssignedTo' 
                ? t("metrics.total_cost.show_by", { type: t("metrics.total_cost.assigner") }) 
                : t("metrics.total_cost.show_by", { type: t("metrics.total_cost.assignee") })}
            </Button>
            {isFullScreen && (
              <div className="flex gap-2">
                <Select
                  value={pageSize.toString()}
                  onValueChange={handlePageSizeChange}
                >
                  <SelectTrigger className="w-32 h-8 bg-white border-purple-200 hover:border-purple-300 focus:ring-1 focus:ring-purple-300 text-[0.8em]">
                    <SelectValue placeholder={t('metrics.charts.persons.selectRows')} />
                  </SelectTrigger>
                  <SelectContent className="z-[9999]" position="popper" sideOffset={4}>
                    {[5, 10, 25, 50, 100].map((size) => (
                      <SelectItem key={size} value={size.toString()} className="text-[0.8em]">
                        {size} {t('metrics.charts.persons.rows')}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>

                <Select
                  value={sortOrder}
                  onValueChange={(value: 'desc' | 'asc') => setSortOrder(value)}
                >
                  <SelectTrigger className="w-32 h-8 bg-white border-purple-200 hover:border-purple-300 focus:ring-1 focus:ring-purple-300 text-[0.8em]">
                    <SelectValue placeholder={t('metrics.charts.cost.selectOrder')} />
                  </SelectTrigger>
                  <SelectContent className="z-[9999]" position="popper" sideOffset={4}>
                    <SelectItem value="desc" className="text-[0.8em]">
                      {t('metrics.charts.cost.highestCost')}
                    </SelectItem>
                    <SelectItem value="asc" className="text-[0.8em]">
                      {t('metrics.charts.cost.lowestCost')}
                    </SelectItem>
                  </SelectContent>
                </Select>
              </div>
            )}
          </div>
        </CardHeader>
        <CardContent className={isFullScreen ? 'h-[calc(100vh-120px)]' : ''}>
          <ChartErrorBoundary>
            <ResponsiveContainer 
              width="100%" 
              height={isFullScreen ? "100%" : 400}
              style={{ overflowY: 'auto', overflowX: 'hidden' }}
            >
              <BarChart
                data={data}
                layout="vertical"
                margin={{ top: 5, right: 30, left: 0, bottom: 5 }}
                onClick={(e) => {
                  if (e && e.activePayload) {
                    handleBarClick(e.activePayload[0].payload);
                  }
                }}
              >
                <XAxis type="number" />
                <YAxis 
                  dataKey="name" 
                  type="category" 
                  width={120}
                  style={{ fontSize: '12px' }}
                />
                <Tooltip content={<CustomTooltip />} />
                <CartesianGrid strokeDasharray="3 3" />
                {taskBars}
              </BarChart>
            </ResponsiveContainer>
          </ChartErrorBoundary>
        </CardContent>
      </Card>
    </div>
  );
}