import { Skeleton } from "@/components/ui/skeleton";
import { useToast } from "@/components/ui/use-toast";
import { authedFetch, fetcher } from "@/lib/fetcher";
import { Key } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { XIcon } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useForm } from "react-hook-form";
import { FetchError } from "@/components/error";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Progress } from "@/components/ui/progress";
import { Users, Building2, ClipboardList, CheckSquare } from "lucide-react";

function CreateToken({ done }: { done: () => void }) {
  const { toast } = useToast();
  const { t } = useTranslation();

  const { register, handleSubmit } = useForm();

  async function action(data: any) {
    try {
      await authedFetch("/v1/admin/token/create", {
        method: "POST",
        body: JSON.stringify(data),
      });

      toast({ title: t("admin.token.create.toast") });

      done();
    } catch (e) {
      toast({ title: t("admin.token.create.toast_error") });
    }
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="my-2" variant="outline">
          {t("admin.token.create.button")}
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{t("admin.token.create.title")}</DialogTitle>
          <DialogDescription>
            {t("admin.token.create.description")}
          </DialogDescription>
        </DialogHeader>
        <form onSubmit={handleSubmit(action)}>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="email" className="text-right">
                {t("admin.token.create.email")}
              </Label>
              <Input
                id="email"
                type="email"
                required
                className="col-span-3"
                {...register("email", { required: true })}
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="note" className="text-right">
                {t("admin.token.create.note")}
              </Label>
              <Input
                id="note"
                required
                className="col-span-3"
                {...register("note", { required: true })}
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="lang" className="text-right">
                {t("admin.token.create.lang")}
              </Label>
              <select {...register("lang", { required: true })}>
                <option value="es">es</option>
                <option value="en">en</option>
              </select>
            </div>
          </div>
          <DialogFooter>
            <Button type="submit">{t("admin.token.create.submit")}</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}

function InvalidateToken({ id, done }: { id: string; done: () => void }) {
  const { toast } = useToast();
  const { t } = useTranslation();

  async function action() {
    const req = await authedFetch(`/v1/admin/token/invalidate/${id}`, {
      method: "DELETE",
    });

    if (req.ok) {
      toast({
        title: t("admin.token.invalidate.toast"),
      });

      done();
    } else {
      toast({ title: t("admin.token.invalidate.toast_error") });
    }
  }

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <button className="mx-1 border p-1 rounded-lg hover:bg-slate-100">
          <XIcon />
        </button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            {t("admin.token.invalidate.title")}
          </AlertDialogTitle>
          <AlertDialogDescription>
            {t("admin.token.invalidate.description")}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>
            {t("admin.token.invalidate.cancel")}
          </AlertDialogCancel>
          <AlertDialogAction onClick={action}>
            {t("admin.token.invalidate.action")}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

function Tokens() {
  const { t } = useTranslation();
  const { data, error, mutate } = useSWR("/v1/admin/token/all", fetcher);

  if (error) return <FetchError />;

  if (!data) {
    return (
      <div className="max-w-xl my-6">
        <Skeleton className="h-10 w-60 my-2" />
        <Skeleton className="h-8 w-52 my-2" />
        <div>
          <Skeleton className="h-16 w-full my-2" />
          <Skeleton className="h-16 w-full my-2" />
          <Skeleton className="h-16 w-full my-2" />
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-xl my-6">
      <h2 className="scroll-m-20 text-2xl font-semibold tracking-tight">
        {t("admin.token.title")}
      </h2>
      <p className="leading-7 mb-2">{t("admin.token.description")}</p>
      <CreateToken done={mutate} />
      <div>
        {data &&
          data.data &&
          data.data.map(
            (
              i: { id: string; email: string; note: string | undefined },
              key: Key
            ) => (
              <div
                className="border p-4 flex justify-between items-center"
                key={key}
              >
                <div className="flex items-center justify-between gap-2">
                  <div>
                    <p className="font-bold">{i.email}</p>
                    {i.note && <p>{i.note}</p>}
                  </div>
                </div>
                <InvalidateToken id={i.id} done={mutate} />
              </div>
            )
          )}
      </div>
    </div>
  );
}

function Stats() {
  const { data: stats } = useSWR<{
    users: number;
    orgs: number;
    tasks: number;
    tasks_done: number;
  }>("/v1/admin/stats", fetcher);

  if (!stats) return null;

  const taskCompletionRate = (stats.tasks_done / stats.tasks) * 100;

  return (
    <div className="py-6 space-y-6 rounded-lg">
      <h2 className="text-2xl font-bold text-gray-800">
        Pulpoo-wide Statistics
      </h2>
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4">
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Total Users</CardTitle>
            <Users className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{stats.users}</div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Organizations</CardTitle>
            <Building2 className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{stats.orgs}</div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Total Tasks</CardTitle>
            <ClipboardList className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{stats.tasks}</div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">
              Completed Tasks
            </CardTitle>
            <CheckSquare className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{stats.tasks_done}</div>
          </CardContent>
        </Card>
      </div>
      <Card>
        <CardHeader>
          <CardTitle>Task Completion Progress</CardTitle>
        </CardHeader>
        <CardContent>
          <Progress value={taskCompletionRate} className="w-full" />
          <p className="mt-2 text-sm text-muted-foreground">
            {stats.tasks_done} out of {stats.tasks} tasks completed (
            {taskCompletionRate.toFixed(1)}%)
          </p>
        </CardContent>
      </Card>
    </div>
  );
}

export function SuperAdmin() {
  const { t } = useTranslation();

  return (
    <section>
      <h1 className="scroll-m-20 text-3xl font-semibold tracking-tight first:mt-0">
        {t("admin.title")}
      </h1>
      <Stats />
      <Tokens />
    </section>
  );
}
