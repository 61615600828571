import React, { ReactNode } from "react";
import ReactDOM from "react-dom/client";
import "@/index.css";
import { Redirect, Route, useLocation } from "wouter";
import { AuthProvider, useAuth } from "@/lib/auth";
import { Login } from "@/views/login";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import ICU from "i18next-icu";
import en from "@/i18n/en.json";
import es from "@/i18n/es.json";
import { Join } from "@/views/join";
import { Toaster } from "@/components/ui/toaster";
import { Dashboard } from "@/views/dashboard";
import { Loader } from "@/components/ui/loader";
import { StripeSuccess } from "@/views/stripe/sucess";
import { StripeCancel } from "@/views/stripe/cancel";
import { MyOrg } from "@/views/my-org";
import { Users } from "@/views/users";
import { Settings } from "@/views/settings";
import { SuperAdmin } from "@/views/superadmin";
import { ResetPasswordPage } from "@/views/reset-password";
import { Home } from "@/views/home";
import { Calendar } from "@/views/calendar";
import { Employees } from "@/views/employees";
import { Projects } from "@/views/projects";
import { CalendarProvider } from "@/lib/calendar";
import { TaskSheetProvider } from "@/lib/sheet/index";
import * as Sentry from "@sentry/react";
import { IS_PROD } from "@/globals";
import { NeedPermissions } from "@/components/permissions";
import { CreateTask } from "./views/create-task";
import { Metrics } from "./views/metrics";

Sentry.init({
  dsn: IS_PROD
    ? "https://a68265dd643551f0cef149fb53e05de4@o4507025511809024.ingest.us.sentry.io/4507411675152384"
    : "",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [/^https:\/\/api\.pulpoo\.com/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

i18n
  .use(initReactI18next)
  .use(ICU)
  .use(LanguageDetector)
  .init({
    resources: {
      en: { translation: en },
      es: { translation: es },
    },
    fallbackLng: "es",
  });

function ProtectedRoute({ children }: { children: ReactNode }) {
  const { user } = useAuth();

  if (!user?.name) {
    return <Redirect href="~/" />;
  }

  return children;
}

function Routing() {
  return (
    <>
      <Route path="/">
        <Login />
      </Route>
      <Route path="/join/:token">
        {(params) => <Join code={params.token} />}
      </Route>
      <Route path="/stripe_success">
        <StripeSuccess />
      </Route>
      <Route path="/reset">
        <ResetPasswordPage />
      </Route>
      <Route path="/stripe_cancel">
        <StripeCancel />
      </Route>
      <Route path="/testing/calendar" nest>
        <CalendarProvider>
          <Calendar />
        </CalendarProvider>
      </Route>
      <Route path="/home" nest>
        <ProtectedRoute>
          <TaskSheetProvider>
            <Dashboard>
              <Route path="/">
                <Home />
              </Route>
              <Route path="/tasks">
                <CreateTask />
              </Route>
              <Route path="/projects" nest>
                <NeedPermissions minimun="MODERATOR">
                  <Projects />
                </NeedPermissions>
              </Route>
              <Route path="/employees" nest>
                <NeedPermissions minimun="MODERATOR">
                  <Employees />
                </NeedPermissions>
              </Route>
              <Route path="/metrics">
                <NeedPermissions minimun="MODERATOR">
                  <Metrics />
                </NeedPermissions>
              </Route>
              <Route path="/calendar" nest>
                <CalendarProvider>
                  <Calendar />
                </CalendarProvider>
              </Route>
              <Route path="/org">
                <MyOrg />
              </Route>

              <Route path="/users">
                <NeedPermissions minimun="ADMIN">
                  <Users />
                </NeedPermissions>
              </Route>
              <Route path="/settings">
                <NeedPermissions minimun="ADMIN">
                  <Settings />
                </NeedPermissions>
              </Route>

              <Route path="/superadmin">
                <NeedPermissions minimun="SUPERADMIN">
                  <SuperAdmin />
                </NeedPermissions>
              </Route>
            </Dashboard>
          </TaskSheetProvider>
        </ProtectedRoute>
      </Route>
    </>
  );
}

function App() {
  const { isLoading } = useAuth();

  const [pathname] = useLocation();

  if (
    !(
      pathname.includes("join") ||
      pathname.includes("reset") ||
      pathname.includes("testing")
    ) &&
    isLoading
  ) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader />
      </div>
    );
  }

  return <Routing />;
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
    <Toaster />
  </React.StrictMode>
);
