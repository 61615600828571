import { useAuth } from "@/lib/auth";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "./ui/button";

type Role = "USER" | "MODERATOR" | "ADMIN" | "OWNER" | "SUPERADMIN";

const ROLES_HIERARCHY: Role[] = [
  "USER",
  "MODERATOR",
  "ADMIN",
  "OWNER",
  "SUPERADMIN",
];

export function NeedPermissions({
  children,
  minimun,
  showLogout = false,
}: {
  children: ReactNode;
  minimun: Role;
  showLogout?: boolean;
}) {
  const { t } = useTranslation();
  const { user, logout } = useAuth();

  if (
    ROLES_HIERARCHY.indexOf(user?.role as Role) <
    ROLES_HIERARCHY.indexOf(minimun)
  ) {
    return (
      <div
        className={`flex flex-col items-center justify-center w-full h-full ${
          showLogout ? "min-h-screen" : ""
        }`}
      >
        <h2 className="scroll-m-20 pb-2 text-3xl font-semibold tracking-tight first:mt-0 mx-6">
          {t("generic.permissions.title", {
            role: t(`roles.${minimun}`),
          })}
        </h2>
        <p className="leading-7 mb-4">{t("generic.permissions.description")}</p>
        {showLogout && (
          <Button onClick={logout} className="mx-auto">
            {t("navigation.header.logout")}
          </Button>
        )}
      </div>
    );
  }

  return children;
}

export function ShowWithPermissions({
  children,
  minimun,
}: {
  children: ReactNode;
  minimun: Role;
}) {
  const { user } = useAuth();

  if (
    ROLES_HIERARCHY.indexOf(user?.role as Role) <
    ROLES_HIERARCHY.indexOf(minimun)
  ) {
    return null;
  }

  return children;
}
