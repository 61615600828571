import { useEffect, useState } from "react";
import { PieChart, Pie, Tooltip, ResponsiveContainer, Cell } from "recharts";
import { ClipboardList } from "lucide-react";
import { useTranslation } from "react-i18next";

interface StatusChartProps {
  status: {
    PENDING: number;
    IN_PROGRESS: number;
    DONE: number;
    DONE_WITH_ISSUES: number;
    IN_REVIEW: number;
  };
}

export function StatusChart({ status }: StatusChartProps) {
  const { t } = useTranslation();
  const [renderKey, setRenderKey] = useState(0);
  const [dataReady, setDataReady] = useState(false);

  //console.log('Raw Status Data:', status);

  const chartData = [
    {
      name: t("metrics.charts.status.status.PENDING"),
      value: status.PENDING,
      color: "#6C788A",
      id: "PENDING"
    },
    {
      name: t("metrics.charts.status.status.IN_PROGRESS"),
      value: status.IN_PROGRESS,
      color: "#D8A232",
      id: "IN_PROGRESS"
    },
    {
      name: t("metrics.charts.status.status.DONE"),
      value: status.DONE,
      color: "#6FC36D",
      id: "DONE"
    },
    {
      name: t("metrics.charts.status.status.DONE_WITH_ISSUES"),
      value: status.DONE_WITH_ISSUES,
      color: "#E57A42",
      id: "DONE_WITH_ISSUES"
    },
    {
      name: t("metrics.charts.status.status.IN_REVIEW"),
      value: status.IN_REVIEW,
      color: "#3B82F6",
      id: "IN_REVIEW"
    }
  ].filter(item => item.value > 0); // Only include items with values > 0

 

  useEffect(() => {
    if (status) {
      setDataReady(true);
      setRenderKey((prevKey) => prevKey + 1);
    }
  }, [status]);

  const applyColorsToSectors = () => {
    const paths = document.querySelectorAll('.recharts-pie-sector path');
    let allColorsApplied = true;

    paths.forEach((path: any, index: number) => {
      if (chartData[index] && (path as SVGPathElement).style.fill !== chartData[index].color) {
        (path as SVGPathElement).style.fill = chartData[index].color;
        allColorsApplied = false;
      }
    });

    return allColorsApplied;
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const allColorsApplied = applyColorsToSectors();

      if (allColorsApplied) {
        clearInterval(intervalId);
      }
    }, 900);

    return () => clearInterval(intervalId);
  }, [chartData]);

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }: {
    cx: number
    cy: number
    midAngle: number
    innerRadius: number
    outerRadius: number
    percent: number
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)
    const percentValue = (percent * 100).toFixed(0)

    return (
      <g>
        <circle cx={x} cy={y} r={12} fill="rgba(0,0,0,0.5)" />
        <text
          x={x}
          y={y}
          fill="white"
          textAnchor="middle"
          dominantBaseline="central"
          fontSize="12"
          fontWeight="bold"
        >
          {percentValue}%
        </text>
      </g>
    )
  }

  return (
    <div className="bg-white rounded-lg shadow-md p-4 flex flex-col h-full " >
      <div className="text-center mb-4">
        <h2 className="text-xl font-semibold">
          {t("metrics.charts.status.title")}
        </h2>
        <p className="text-sm text-gray-500">
          {t("metrics.charts.status.subtitle")}
        </p>
      </div>
      <div className="flex-grow ">
        {dataReady && (
          <ResponsiveContainer width="100%" height={250}>
            <PieChart key={renderKey}>
              <Pie
                data={chartData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={80}
                labelLine={false}
                label={renderCustomizedLabel}
                
              >
                {chartData.map((entry) => (
                  <Cell 
                    key={entry.id}
                    fill={entry.color}
                  />
                ))}
              </Pie>
              <Tooltip
                content={({ active, payload }) => {
                  if (active && payload && payload.length) {
                    const data = payload[0].payload;
                    return (
                      <div className="bg-white border border-gray-200 p-2 shadow-md rounded">
                        <p className="font-semibold">{data.name}</p>
                        <p className="text-sm">{`${t(
                          "metrics.charts.status.label"
                        )}: ${data.value}`}</p>
                      </div>
                    );
                  }
                  return null;
                }}
              />
            </PieChart>
          </ResponsiveContainer>
        )}
      </div>
      <div className="mt-4 flex items-center justify-center text-gray-500">
        <ClipboardList className="w-4 h-4 mr-2" />
        <span className="text-sm">{t("metrics.charts.status.label")}</span>
      </div>
      <div className="mt-4 grid grid-cols-2 gap-2">
        {chartData.map((entry) => (
          <div key={entry.id} className="flex items-center">
            <div
              className="w-3 h-3 rounded-full mr-2"
              style={{ backgroundColor: entry.color }}
            ></div>
            <span className="text-sm">{entry.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
