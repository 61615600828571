import { baseURL } from "@/globals";
import { ChangeEvent, useRef, useState } from "react";
import { Button } from "./ui/button";
import { useTranslation } from "react-i18next";

export function UploadImage({
  endpoint,
  authorization,
  data,
  onError,
  onChange,
}: {
  endpoint: string;
  authorization: string;
  data: string;
  onError: () => void;
  onChange: (value: string) => void;
}) {
  const { t } = useTranslation();

  const [isPhotoUploading, setIsPhotoUploading] = useState(false);
  const photoRef = useRef<HTMLInputElement>(null);

  const onPhotoChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    setIsPhotoUploading(true);

    const file = e.target.files[0];

    const { type, name } = file;

    try {
      const payloadReq = await fetch(`${baseURL}/v1/blob/${endpoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: !authorization.includes("Bearer")
            ? `Bearer ${authorization}`
            : "",
        },
        credentials: "include",
        body: JSON.stringify({
          type,
          ext: name.split(".").pop(),
        }),
      });

      const { signedUrl, finalUrl } = await payloadReq.json();

      const request = await fetch(signedUrl, {
        method: "PUT",
        headers: {
          "Content-Type": type,
        },
        body: file,
      });

      if (!request.ok) {
        onError();
      }

      onChange(finalUrl);

      setIsPhotoUploading(false);
    } catch (e) {
      onError();
      setIsPhotoUploading(false);
    }
  };

  return !data ? (
    <>
      <input
        id="photo"
        type="file"
        accept="image/*"
        onChange={onPhotoChange}
        className="hidden"
        ref={photoRef}
      />
      <Button
        disabled={isPhotoUploading}
        onClick={() => photoRef.current!.click()}
        type="button"
      >
        {t("auth.join.photoUpload")}
      </Button>
    </>
  ) : (
    <div>
      <img src={data} alt="" className="w-40 rounded border my-2" />
      <Button onClick={() => onChange("")} type="button">
        {t("auth.join.changeImage")}
      </Button>
    </div>
  );
}
