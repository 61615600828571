import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useAuth } from "@/lib/auth";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "wouter";

const loginSchema = z.object({
  email: z
    .string()
    .min(1, { message: "auth.login.errors.emailRequired" })
    .email({ message: "auth.login.errors.invalidEmail" }),
  password: z
    .string()
    .min(8, { message: "auth.login.errors.passwordLength" })
    .refine(
      (value) => /[A-Z]/.test(value) && /[a-z]/.test(value) && /\d/.test(value),
      { message: "auth.login.errors.passwordFormat" }
    ),
});

type Form = z.infer<typeof loginSchema>;

export function Login() {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const { login, user } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<Form>({
    resolver: zodResolver(loginSchema),
  });

  const onSubmit: SubmitHandler<Form> = async (data) => {
    try {
      await login(data.email, data.password);
    } catch (e) {
      setError("root", {
        message: "auth.login.errors.incorrectCredentials",
      });
    }
  };

  if (user) {
    return <Redirect href="/home" />;
  }

  return (
    <div className="flex justify-center items-center min-h-screen">
      <Card className="w-full max-w-md my-6">
        <CardHeader>
          <CardTitle>{t("auth.login.title")}</CardTitle>
        </CardHeader>
        <CardContent>
          <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-1">
              <Label htmlFor="email">{t("auth.login.email")}</Label>
              <Input
                id="email"
                placeholder={t("auth.login.enterEmail")}
                type="email"
                {...register("email")}
              />

              {errors.email && (
                <p className="text-sm text-red-500">
                  {t(errors.email.message || "generic.error")}
                </p>
              )}
            </div>
            <div className="space-y-1 mb-2">
              <Label htmlFor="password">{t("auth.login.password")}</Label>
              <Input
                id="password"
                placeholder={t("auth.login.enterPassword")}
                type="password"
                {...register("password")}
              />

              {errors.password && (
                <p className="text-sm text-red-500">
                  {t(errors.password.message || "generic.error")}
                </p>
              )}
            </div>

            {errors.root && (
              <p className="text-sm text-red-500">
                {t(errors.root.message || "generic.error")}
              </p>
            )}

            <Link
              href="/reset"
              className="text-purple-600 underline hover:no-underline"
            >
              {t("auth.reset.link")}
            </Link>

            <Button className="w-full" type="submit" disabled={isSubmitting}>
              {t("auth.login.accessAccount")}
            </Button>
            <Button
              className="w-full"
              type="button"
              onClick={() => changeLanguage(language === "en" ? "es" : "en")}
            >
              {t("changeLanguage")}
            </Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}
