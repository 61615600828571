import { baseURL } from "@/globals";
import { ReactNode, createContext, useContext } from "react";
import useSWR from "swr";
import { useLocation } from "wouter";

type User = {
  email: string;
  role: string;
  is_active: boolean;
  org_id: string;
  id: string;
  photo: string | null;
  name: string | null;
  caption: string | null;
  org: {
    id: string;
    name: string;
    photo: string | null;
    bio: string;
    is_freeze: boolean;
    created_at: string;
    is_activated: boolean;
  };
};

const fetcher = (url: string) =>
  fetch(url, { credentials: "include" })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      return res.user;
    });

const AuthContext = createContext<{
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  user: User | null;
  isLoading: boolean;
}>({
  login: async (email: string, password: string) =>
    console.log({ email, password }),
  logout: async () => {},
  user: null,
  isLoading: true,
});

export function AuthProvider({ children }: { children: ReactNode }) {
  const { data, mutate, isLoading } = useSWR(`${baseURL}/v1/user/me`, fetcher);

  const [, navigate] = useLocation();

  async function login(email: string, password: string) {
    const res = await fetch(`${baseURL}/v1/auth/admin-login`, {
      credentials: "include",
      method: "POST",
      body: JSON.stringify({ email, password }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (res.ok) {
      mutate();
      navigate("/home");
    } else {
      throw new Error("Invalid login");
    }
  }

  async function logout() {
    await fetch(`${baseURL}/v1/auth/logout`, {
      method: "DELETE",
      credentials: "include",
    });

    mutate();
    navigate("/");
  }

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        user: data,
        isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
