import {
  CheckIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ListRestart,
  XIcon,
} from "lucide-react";
import { Input } from "@/components/ui/input";
import { useTranslation } from "react-i18next";
import { ChangeEvent, Key, useState } from "react";
import useSWR from "swr";
import { authedFetch, fetcher } from "@/lib/fetcher";
import useDebounceState from "@/hooks/debounce";
import { useAuth } from "@/lib/auth";
import { useToast } from "@/components/ui/use-toast";
import {
  Select,
  SelectItem,
  SelectContent,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Skeleton } from "@/components/ui/skeleton";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { UploadImage } from "@/components/upload-photo";
import { stopPropagate } from "@/lib/utils";
import { FetchError } from "@/components/error";

function SelectTeam({ onChange }: { onChange: (value: string) => void }) {
  const formSchema = z.object({
    name: z
      .string()
      .min(1, { message: "users.create.errors.team.nameRequired" }),
  });

  type Form = z.infer<typeof formSchema>;

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<Form>({
    resolver: zodResolver(formSchema),
  });

  const { t } = useTranslation();
  const { toast } = useToast();

  const { data, mutate, error } = useSWR("/v1/teams", fetcher);

  async function action(data: Form) {
    try {
      await authedFetch("/v1/teams/create", {
        method: "POST",
        body: JSON.stringify(data),
      });

      toast({ title: t("users.create.team.toast") });

      mutate();

      reset();
    } catch (e) {
      toast({ title: t("users.create.team.toast_error") });
    }
  }

  if (!data) return <Skeleton className="w-full h-10" />;

  if (error) return <FetchError />;

  return (
    <Select onValueChange={onChange}>
      <SelectTrigger>
        <SelectValue placeholder={t("users.create.teamPlaceholder")} />
      </SelectTrigger>
      <SelectContent>
        {data.teams.map((team: { id: string; name: string }, key: Key) => (
          <SelectItem key={key} value={team.id}>
            {team.name}
          </SelectItem>
        ))}
        <form onSubmit={stopPropagate(handleSubmit(action))}>
          <div className="my-1">
            <div className="flex flex-row items-center gap-1">
              <Input
                placeholder={t("users.create.team.name")}
                {...register("name")}
              />
              <Button
                disabled={isSubmitting}
                type="submit"
                variant="outline"
                size="sm"
              >
                {t("users.create.team.submit")}
              </Button>
            </div>
            {errors.name && (
              <p className="text-sm text-red-500">
                {t(errors.name.message || "generic.error")}
              </p>
            )}
          </div>
        </form>
      </SelectContent>
    </Select>
  );
}

function CreateUserButton() {
  const createSchema = z.object({
    email: z
      .string()
      .email({ message: "users.create.errors.emailInvalid" })
      .min(1, { message: "users.create.errors.emailRequired" }),
    name: z.string().min(1, { message: "users.create.errors.nameRequired" }),
    caption: z
      .string()
      .min(1, { message: "users.create.errors.captionRequired" }),
    photo: z.string().min(1, { message: "users.create.errors.photoRequired" }),
    team: z.string().min(1, { message: "users.create.errors.teamRequired" }),
  });

  type Form = z.infer<typeof createSchema>;

  const { toast } = useToast();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    setValue,
    setError,
    reset,
  } = useForm<Form>({
    resolver: zodResolver(createSchema),
  });

  const photoData = watch("photo");

  const teamData = watch("team");

  async function action(data: any) {
    try {
      const payload = {
        ...data,
        lang: t("lang"),
      };

      await authedFetch("/v1/auth/register", {
        method: "POST",
        body: JSON.stringify(payload),
      });

      toast({ title: t("users.create.toast") });

      reset({
        team: teamData,
      });
    } catch (e) {
      toast({ title: t("users.create.toast_error") });
    }
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="my-2">{t("users.create.button")}</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle>{t("users.create.title")}</DialogTitle>
          <DialogDescription>{t("users.create.description")}</DialogDescription>
        </DialogHeader>
        <form onSubmit={handleSubmit(action)}>
          <div className="grid gap-4 py-4">
            <div className="flex flex-col gap-2">
              <Label htmlFor="email">{t("users.create.email")}</Label>
              <Input
                id="email"
                type="email"
                required
                className="col-span-3"
                placeholder={t("users.create.emailPlaceholder")}
                {...register("email")}
              />
              {errors.email && (
                <p className="text-sm text-red-500">
                  {t(errors.email.message || "generic.error")}
                </p>
              )}
            </div>
            <div className="flex flex-col gap-2">
              <Label htmlFor="name">{t("users.create.name")}</Label>
              <Input
                id="name"
                type="text"
                required
                className="col-span-3"
                placeholder={t("users.create.namePlaceholder")}
                {...register("name")}
              />
              {errors.name && (
                <p className="text-sm text-red-500">
                  {t(errors.name.message || "generic.error")}
                </p>
              )}
            </div>
            <div className="flex flex-col gap-2">
              <Label htmlFor="caption">{t("users.create.caption")}</Label>
              <Input
                id="caption"
                type="text"
                required
                className="col-span-3"
                placeholder={t("users.create.captionPlaceholder")}
                {...register("caption")}
              />
              {errors.caption && (
                <p className="text-sm text-red-500">
                  {t(errors.caption.message || "generic.error")}
                </p>
              )}
            </div>
            <div className="flex flex-col gap-2">
              <Label htmlFor="team">{t("users.create.team.key")}</Label>
              <SelectTeam onChange={(value) => setValue("team", value)} />
              {errors.team && (
                <p className="text-sm text-red-500">
                  {t(errors.team.message || "generic.error")}
                </p>
              )}
            </div>
            <div className="flex flex-col gap-2">
              <Label htmlFor="photo">{t("users.create.photo")}</Label>
              <UploadImage
                data={photoData}
                endpoint="user-picture"
                authorization="credentials"
                onError={() =>
                  setError("photo", {
                    message: "auth.join.errors.photoUploadingFailed",
                  })
                }
                onChange={(value) => setValue("photo", value)}
              />
              {errors.photo && (
                <p className="text-sm text-red-500">
                  {t(errors.photo.message || "generic.error")}
                </p>
              )}
            </div>
          </div>
          <DialogFooter>
            <Button disabled={isSubmitting} type="submit">
              {t("users.create.submit")}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}

function Explorer({
  url,
  title,
  description,
  Item,
}: {
  url: string;
  title: string;
  description: string;
  Item: ({ data, mutate }: { data: any; mutate: () => void }) => JSX.Element;
}) {
  const { t } = useTranslation();

  const [page, setPage] = useState(1);
  const [query, debouncedQuery, setQuery] = useDebounceState("", 800);

  const { data, mutate, error } = useSWR(
    `${url}?page=${page}&q=${debouncedQuery}`,
    fetcher
  );

  if (error) return <FetchError />;

  if (!data) {
    return (
      <div className="max-w-2xl my-6">
        <Skeleton className="h-10 w-60 my-2" />
        <Skeleton className="h-8 w-52 my-2" />
        <div className="my-2 flex items-center">
          <Skeleton className="h-8 w-1/2 mr-1" />
          <Skeleton className="h-6 w-6 m-1" />
        </div>
        <div>
          <Skeleton className="h-16 w-full my-2" />
          <Skeleton className="h-16 w-full my-2" />
          <Skeleton className="h-16 w-full my-2" />
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-2xl my-6">
      <h2 className="scroll-m-20 text-2xl font-semibold tracking-tight">
        {t(title, { qt: data.total })}
      </h2>
      <p className="leading-7 mb-2">{t(description)}</p>
      <div className="my-2 flex items-center">
        <Input
          className="w-1/2"
          placeholder={t("users.explorer.placeholder")}
          value={query}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setQuery(e.target.value);
          }}
        />
        <button
          className="mx-1 border p-1 rounded-lg hover:bg-slate-100"
          onClick={() => mutate()}
        >
          <ListRestart />
        </button>
      </div>
      <div>
        {data.users.map((user: any, key: Key) => (
          <Item key={key} data={user} mutate={mutate} />
        ))}
      </div>
      <div className="flex items-center my-2">
        <button
          className="border p-1 rounded-l-lg hover:bg-slate-100 disabled:cursor-not-allowed disabled:hover:bg-slate-200 disabled:bg-slate-200"
          onClick={() => {
            setPage(page - 1);
          }}
          disabled={page <= 1}
        >
          <ChevronLeftIcon />
        </button>
        <button
          className="border p-1 rounded-r-lg hover:bg-slate-100 disabled:cursor-not-allowed disabled:hover:bg-slate-200 disabled:bg-slate-200"
          onClick={() => {
            setPage(page + 1);
          }}
          disabled={page >= data.pages}
        >
          <ChevronRightIcon />
        </button>
        <p className="mx-2">
          {t("users.explorer.page", { page: page, pages: data.pages })}
        </p>
      </div>
    </div>
  );
}

function RoleSelector({
  email,
  defaultRole,
  done,
}: {
  email: string;
  defaultRole: string;
  done: () => void;
}) {
  const { user } = useAuth();
  const { toast } = useToast();
  const { t } = useTranslation();

  async function action(role: string) {
    const req = await authedFetch("/v1/org/permissions/change-permission", {
      method: "PUT",
      body: JSON.stringify({
        email,
        role,
      }),
    });

    if (req.ok) {
      toast({
        title: t("users.permissions.toast", {
          user: email,
          role: t(`roles.${role}`),
        }),
      });

      done();
    } else {
      toast({ title: t("users.permissions.toast_error") });
    }
  }

  if (
    defaultRole === "OWNER" ||
    defaultRole === "SUPERADMIN" ||
    (user?.role === "ADMIN" &&
      (defaultRole === "OWNER" ||
        defaultRole === "SUPERADMIN" ||
        defaultRole === "ADMIN"))
  )
    return <p>{t(`roles.${defaultRole}`)}</p>;

  const roles = ["USER", "MODERATOR"];

  if (user?.role === "OWNER" || user?.role === "SUPERADMIN")
    roles.push("ADMIN");

  return (
    <Select onValueChange={action} defaultValue={defaultRole}>
      <SelectTrigger className="w-[180px]">
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        {roles.map((role, key) => (
          <SelectItem value={role} key={key}>
            {t(`roles.${role}`)}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}

function RemoveActiveUserButton({
  email,
  defaultRole,
  done,
}: {
  email: string;
  defaultRole: string;
  done: () => void;
}) {
  const { toast } = useToast();
  const { t } = useTranslation();
  const { user } = useAuth();

  const [doubleCheck, setDoubleCheck] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function action() {
    if (isLoading) return;

    try {
      setIsLoading(true);

      const req = await authedFetch("/v1/org/permissions/remove", {
        method: "DELETE",
        body: JSON.stringify({
          email,
        }),
      });

      if (!req.ok) throw new Error("Failed to remove user");

      toast({
        title: t("users.remove.toast", {
          user: email,
        }),
      });

      done();
    } catch (e) {
      toast({ title: t("users.remove.toast_error") });
    } finally {
      setIsLoading(false);
    }
  }

  if (
    defaultRole === "OWNER" ||
    defaultRole === "SUPERADMIN" ||
    (user?.role === "ADMIN" &&
      (defaultRole === "OWNER" ||
        defaultRole === "SUPERADMIN" ||
        defaultRole === "ADMIN"))
  )
    return <></>;

  return (
    <div className="flex items-center gap-2 ml-1">
      {doubleCheck && (
        <Button onClick={action} disabled={isLoading}>
          {t("users.remove.double_check")}
        </Button>
      )}
      <Button
        size="icon"
        variant="outline"
        onClick={() => setDoubleCheck((state) => !state)}
      >
        <XIcon />
      </Button>
    </div>
  );
}

function AcceptInactiveUserButton({
  email,
  done,
}: {
  email: string;
  done: () => void;
}) {
  const { toast } = useToast();
  const { t } = useTranslation();

  async function action() {
    const req = await authedFetch("/v1/org/permissions/accept", {
      method: "POST",
      body: JSON.stringify({
        email,
      }),
    });

    if (req.ok) {
      toast({
        title: t("users.accept.toast", {
          user: email,
        }),
      });

      done();
    } else {
      toast({ title: t("users.accept.toast_error") });
    }
  }

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <button className="mx-1 border p-1 rounded-lg hover:bg-slate-100">
          <CheckIcon />
        </button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{t("users.accept.modal.title")}</AlertDialogTitle>
          <AlertDialogDescription>
            {t("users.accept.modal.description")}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>
            {t("users.accept.modal.cancel")}
          </AlertDialogCancel>
          <AlertDialogAction onClick={action}>
            {t("users.accept.modal.action")}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

function DeleteInactiveUserButton({
  email,
  done,
}: {
  email: string;
  done: () => void;
}) {
  const { toast } = useToast();
  const { t } = useTranslation();

  async function action() {
    const req = await authedFetch("/v1/org/permissions/reject", {
      method: "DELETE",
      body: JSON.stringify({
        email,
      }),
    });

    if (req.ok) {
      toast({
        title: t("users.reject.toast", {
          user: email,
        }),
      });

      done();
    } else {
      toast({ title: t("users.reject.toast_error") });
    }
  }

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <button className="mx-1 border p-1 rounded-lg hover:bg-slate-100">
          <XIcon />
        </button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{t("users.reject.modal.title")}</AlertDialogTitle>
          <AlertDialogDescription>
            {t("users.reject.modal.description")}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>
            {t("users.reject.modal.cancel")}
          </AlertDialogCancel>
          <AlertDialogAction onClick={action}>
            {t("users.reject.modal.action")}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export function Users() {
  const { t } = useTranslation();

  return (
    <section>
      <div className="flex items-center">
        <h1 className="scroll-m-20 text-3xl font-semibold tracking-tight first:mt-0 mr-2">
          {t("users.title")}
        </h1>
        <CreateUserButton />
      </div>
      <Explorer
        url="/v1/org/users/authorized"
        title="users.auth.title"
        description="users.auth.description"
        Item={({ data, mutate }) => (
          <div className="border p-4 flex justify-between items-center">
            <div className="flex items-center justify-between gap-2">
              {data.photo && (
                <img
                  src={data.photo}
                  alt=""
                  className="h-12 w-12 rounded-full border"
                />
              )}
              <div>
                {data.name && <p className="font-bold">{data.name}</p>}
                <p>{data.email}</p>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <RoleSelector
                email={data.email}
                defaultRole={data.role}
                done={mutate}
              />
              <RemoveActiveUserButton
                email={data.email}
                defaultRole={data.role}
                done={mutate}
              />
            </div>
          </div>
        )}
      />
      <Explorer
        url="/v1/org/users/unauthorized"
        title="users.unauth.title"
        description="users.unauth.description"
        Item={({ data, mutate }) => (
          <div className="border p-4 flex justify-between items-center">
            <p>{data.email}</p>
            <div className="flex items-center justify-between">
              <AcceptInactiveUserButton email={data.email} done={mutate} />
              <DeleteInactiveUserButton email={data.email} done={mutate} />
            </div>
          </div>
        )}
      />
    </section>
  );
}
