import { createContext, useContext, useState, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Sheet, SheetContent } from "@/components/ui/sheet";

import { TaskDetails } from "./task";
import { DayDetails } from "./day";
import { DialogTitle } from "@radix-ui/react-dialog";

export const TaskContext = createContext<{
  controlSheet: {
    openWithTask: (taskId: string) => void;
    openWithDay: (dayId: string) => void;
    navigateToTask: (taskId: string) => void;
    navigateBack: () => void;
    close: () => void;
  };
  filters: {
    pending: boolean;
    inProgress: boolean;
    inReview: boolean;
    done: boolean;
    selectedUsers: string[];
    selectedProjects: string[];
    assignedByUsers: string[];
  };
  setFilters: (filters: {
    pending: boolean;
    inReview: boolean;
    inProgress: boolean;
    done: boolean;
    selectedUsers: string[];
    selectedProjects: string[];
    assignedByUsers: string[];
  }) => void;
  isSheetOpen: boolean;
}>({
  controlSheet: {
    openWithTask: () => {},
    openWithDay: () => {},
    navigateToTask: () => {},
    navigateBack: () => {},
    close: () => {},
  },
  filters: {
    pending: true,
    inReview: true,
    inProgress: false,
    done: true,
    selectedUsers: [],
    selectedProjects: [],
    assignedByUsers: [],
  },
  setFilters: () => {},
  isSheetOpen: false,
});

export function TaskSheetProvider({ children }: { children: ReactNode }) {
  const { t } = useTranslation();

  const [filters, setFilters] = useState<{
    pending: boolean;
    inReview: boolean;
    inProgress: boolean;
    done: boolean;
    selectedUsers: string[];
    selectedProjects: string[];
    assignedByUsers: string[];
  }>({
    pending: true,
    inReview: true,
    inProgress: true,
    done: true,
    selectedUsers: [],
    selectedProjects: [],
    assignedByUsers: [],
  });

  const [isSheetOpen, setSheetOpen] = useState(false);
  const [elements, setElements] = useState<string[]>([]);

  const canGoBack = elements.length > 1;

  const controlSheet = {
    openWithTask: (taskId: string) => {
      setElements([`task:${taskId}`]);
      setSheetOpen(true);
    },
    openWithDay: (dayId: string) => {
      setElements([`day:${dayId}`]);
      setSheetOpen(true);
    },
    navigateToTask: (taskId: string) => {
      setElements([...elements, `task:${taskId}`]);
    },
    navigateBack: () => {
      if (!canGoBack) return;

      setElements(elements.slice(0, -1));
    },
    close: () => {
      setElements([]);
      setSheetOpen(false);
    },
  };

  const sheetData = {
    controlSheet,
    filters,
    setFilters,
    isSheetOpen,
  };

  const selectedElement = elements.at(-1) ?? "";

  return (
    <TaskContext.Provider value={sheetData}>
      <Sheet open={isSheetOpen} onOpenChange={(status) => setSheetOpen(status)}>
        {children}
        <SheetContent className="w-[450px] sm:max-w-full overflow-y-scroll">
          {(selectedElement?.length ?? 0) > 0 ? (
            selectedElement.startsWith("day:") ? (
              <DayDetails
                id={selectedElement.split(":")[1]}
                filters={filters}
              />
            ) : (
              <TaskDetails
                id={selectedElement.split(":")[1]}
                canGoBack={canGoBack}
                goBack={controlSheet.navigateBack}
                nextTask={controlSheet.navigateToTask}
                closeSheet={controlSheet.close}
              />
            )
          ) : (
            <DialogTitle>{t("generic.error")}</DialogTitle>
          )}
        </SheetContent>
      </Sheet>
    </TaskContext.Provider>
  );
}

export const useTaskSheet = () => useContext(TaskContext);
