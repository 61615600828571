interface PaginationPagesProps {
  page: number;
  totalPages: number;
  setPage: (page: number) => void;
}

export function PaginationPages({ page, totalPages, setPage }: PaginationPagesProps) {
  // Don't render pagination if there's only 1 page or less
  if (totalPages <= 1) return null;

  // Calculate which page numbers to show
  const getPageNumbers = () => {
    const pages: (number | string)[] = [];
    
    if (totalPages <= 7) {
      // Show all pages if total is 7 or less
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    // Always show first page
    pages.push(1);

    if (page > 3) {
      pages.push('...');
    }

    // Show pages around current page
    for (let i = Math.max(2, page - 1); i <= Math.min(totalPages - 1, page + 1); i++) {
      pages.push(i);
    }

    if (page < totalPages - 2) {
      pages.push('...');
    }

    // Always show last page
    pages.push(totalPages);

    return pages;
  };

  return (
    <div className="flex items-center gap-2">
      <button
        onClick={() => setPage(page - 1)}
        disabled={page === 1}
        className={`px-3 py-1 rounded ${
          page === 1
            ? "bg-secondary/50 cursor-not-allowed"
            : "bg-secondary hover:bg-secondary/80"
        }`}
      >
        Previous
      </button>

      {getPageNumbers().map((pageNum, index) => (
        <button
          key={index}
          onClick={() => typeof pageNum === 'number' && setPage(pageNum)}
          className={`px-3 py-1 rounded ${
            pageNum === page
              ? "bg-primary text-primary-foreground"
              : pageNum === '...'
              ? "bg-transparent cursor-default"
              : "bg-secondary hover:bg-secondary/80"
          }`}
        >
          {pageNum}
        </button>
      ))}

      <button
        onClick={() => setPage(page + 1)}
        disabled={page === totalPages}
        className={`px-3 py-1 rounded ${
          page === totalPages
            ? "bg-secondary/50 cursor-not-allowed"
            : "bg-secondary hover:bg-secondary/80"
        }`}
      >
        Next
      </button>
    </div>
  );
} 