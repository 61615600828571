import { FetchError } from "@/components/error";
import { Loading } from "@/components/loading";
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "@/components/ui/accordion";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { Loader } from "@/components/ui/loader";
import { fetcher } from "@/lib/fetcher";
import { AvatarImage } from "@radix-ui/react-avatar";
import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { CompletenessChart } from "./completeness";
import { StatusChart } from "./status";
import DateRangePicker from "@/components/ui/daterange";
import { DateRange } from "react-day-picker";
import { startOfDay, endOfDay } from "date-fns";
import { Select,} from "@/components/ui/select"
import { TotalCost } from "./totalCost";
import { TableTask } from "./tableTask";
import { ChannelTasks } from "./channelTasks";
import { MetricsSummary } from "./summary";
import {
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent,
} from "@/components/ui/tabs";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from "@/components/ui/card";
import { PriceChannel } from "./priceChannel";
import { TasksPerPerson } from './tasksPerPerson'
import { ChartErrorBoundary } from "@/components/error/ChartErrorBoundary";
import { X } from "lucide-react";

interface MetricsPayload {
  projects: {
    id: string;
    name: string;
    photo: string;
    people: {
      id: string;
      name: string;
      photo: string;
      
    }[];
  }[];
}

interface MetricsData {
  completeness: {
    total: number;
    completed: number;
  };

  completenessLate: {
    totalLateTasks: number;
    completedOnTime: number;
  };

  priceMetrics: {
    totalPrice: number;
    byAssignedTo: {
      [key: string]: {
        name: string;
        totalPrice: number;
        tasks: Array<{
          id: string;
          price: number;
          title: string;
          channel: {
            id: string;
            name: string;
          };
        }>;
      };
    };
    byAssignedBy: {
      [key: string]: {
        name: string;
        totalPrice: number;
        tasks: Array<{
          id: string;
          price: number;
          title: string;
          channel: {
            id: string;
            name: string;
          };
        }>;
      };
    };
  };

  channelMetrics: {
    [channelId: string]: {
      total: number;
      PENDING: number;
      IN_PROGRESS: number;
      IN_REVIEW: number;
      DONE_WITH_ISSUES: number;
      DONE: number;
    };
  };
  status?: {
    PENDING: number;
    IN_PROGRESS: number;
    DONE: number;
    DONE_WITH_ISSUES: number;
    IN_REVIEW: number;
  };
  statusByPerson?: {
    [key: string]: {
      id: string;
      name: string;
      total: number;
      PENDING: number;
      IN_PROGRESS: number;
      DONE: number;
      DONE_WITH_ISSUES: number;
      IN_REVIEW: number;
    };
  };

  channelPriceMetrics?: {
    totalPrice: number;
    byChannel: {
      [key: string]: {
        name: string;
        totalPrice: number;
        tasks: Array<{
          id: string;
          price: number;
          title: string;
          channel: {
            id: string;
            name: string;
          };
        }>;
      };
    };
    pagination: {
      total: number;
      currentPage: number;
      pageSize: number;
      totalPages: number;
      hasMore: boolean;
    };
  };
}

interface ChannelMetricsRowProps {
  data: MetricsData;
  t: (key: string, options?: any) => string;
  selectedProjects: string[];
  selectedPersons: string[];
  selectedRange?: DateRange;
  onTaskSelect: (tasks: string[]) => void;
  setSelectedTaskFiltersChannel: (filters: { channelId?: string; status?: string; } | null) => void;
  selectedTaskFiltersChannel: { channelId?: string; status?: string; } | null;
  selectedTaskIds: string[];
  setSelectedTaskIds: (taskIds: string[]) => void;
  activeSection: string | null;
  setActiveSection: (section: 'totalCost' | 'channel' | null) => void;
}

const ChannelMetricsRow = ({ 
  t, 
  selectedProjects, 
  selectedPersons, 
  selectedRange,
  setSelectedTaskFiltersChannel,
  selectedTaskFiltersChannel,
  selectedTaskIds,
  setSelectedTaskIds,
  activeSection,
  setActiveSection
}: ChannelMetricsRowProps) => (
  <>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      <Card className="border-2 border-purple-200 hover:border-purple-300 transition-all duration-300 shadow-md hover:shadow-lg">
        <CardHeader className="bg-purple-50 rounded-t-lg">
          <CardTitle className="text-purple-800">{t("metrics.charts.price_channel.title")}</CardTitle>
        </CardHeader>
        <CardContent className="pt-6">
          <PriceChannel
            selectedProjects={selectedProjects}
            selectedPersons={selectedPersons}
            selectedRange={selectedRange}
            onTaskSelect={(tasks: string[]) => {
              setSelectedTaskFiltersChannel(null);
              setSelectedTaskIds(tasks);
              setActiveSection('channel');
            }}
          />
        </CardContent>
      </Card>

      <Card className="border-2 border-purple-200 hover:border-purple-300 transition-all duration-300 shadow-md hover:shadow-lg">
        <CardHeader className="bg-purple-50 rounded-t-lg">
          <CardTitle className="text-purple-800">{t("metrics.charts.channels.title")}</CardTitle>
        </CardHeader>
        <CardContent className="pt-6">
          <ChannelTasks
            selectedProjects={selectedProjects}
            selectedPersons={selectedPersons}
            selectedRange={selectedRange}
            onTaskSelect={(channelId, status) => {
              setSelectedTaskFiltersChannel({
                channelId,
                status: status === 'TOTAL' ? undefined : status
              });
            }}
          />
        </CardContent>
      </Card>
    </div>

    {selectedTaskFiltersChannel && (
      <Card className="mt-6 border-2 border-purple-200 shadow-md">
        <CardHeader className="bg-purple-50 rounded-t-lg">
          <div className="flex justify-between items-center">
            <CardTitle className="text-purple-800">
              {t("metrics.charts.channels.title")}
            </CardTitle>
            <Button
              variant="ghost"
              size="icon"
              onClick={() => setSelectedTaskFiltersChannel(null)}
            >
              <X className="h-4 w-4" />
            </Button>
          </div>
        </CardHeader>
        <CardContent>
          <TableTask 
            channelId={selectedTaskFiltersChannel.channelId}
            status={selectedTaskFiltersChannel.status}
            selectedProjects={selectedProjects}
            selectedPersons={selectedPersons}
            selectedRange={selectedRange}
          />
        </CardContent>
      </Card>
    )}

    {activeSection === 'channel' && selectedTaskIds.length > 0 && (
      <Card className="mt-6 border-2 border-purple-200 shadow-md">
        <CardHeader className="bg-purple-50 rounded-t-lg">
          <div className="flex justify-between items-center">
            <CardTitle className="text-purple-800">
              {t("metrics.charts.price_channel.title")}
            </CardTitle>
            <Button
              variant="ghost"
              size="icon"
              onClick={() => {
                setSelectedTaskIds([]);
                setActiveSection(null);
              }}
            >
              <X className="h-4 w-4" />
            </Button>
          </div>
        </CardHeader>
        <CardContent>
          <TableTask 
            taskIds={selectedTaskIds}
            selectedProjects={selectedProjects}
            selectedPersons={selectedPersons}
            selectedRange={selectedRange}
          />
        </CardContent>
      </Card>
    )}
  </>
);

const CompletionStatusRow = ({ data, t }: { 
  data: MetricsData; 
  t: (key: string, options?: any) => string;
}) => (
  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
    <Card className="border-2 border-purple-200 hover:border-purple-300 transition-all duration-300 shadow-md hover:shadow-lg">
      <CardHeader className="bg-purple-50 rounded-t-lg">
        <CardTitle className="text-purple-800">{t("metrics.completeness.title")}</CardTitle>
      </CardHeader>
      <CardContent className="pt-6">
        <ChartErrorBoundary>
          <CompletenessChart
            total={data.completeness.total}
            completed={data.completeness.completed}
            completedOnTime={data.completenessLate.completedOnTime}
          />
        </ChartErrorBoundary>
      </CardContent>
    </Card>

    <Card className="border-2 border-purple-200 hover:border-purple-300 transition-all duration-300 shadow-md hover:shadow-lg">
      <CardHeader className="bg-purple-50 rounded-t-lg">
        <CardTitle className="text-purple-800">{t("metrics.charts.status.title")}</CardTitle>
      </CardHeader>
      <CardContent className="pt-6">
        {data.status && <StatusChart status={data.status} />}
      </CardContent>
    </Card>
  </div>
);

interface TotalCostRowProps {
  data: MetricsData;
  t: (key: string, options?: any) => string;
  dataSource: string;
  selectedProjects: string[];
  selectedPersons: string[];
  selectedRange?: DateRange;
  onTaskSelect: (tasks: string[]) => void;
  setSelectedTaskFilters: (filters: { personId?: string; status?: string; } | null) => void;
  setSelectedTaskFiltersChannel: (filters: { channelId?: string; status?: string; } | null) => void;
  setActiveSection: (section: 'totalCost' | 'channel' | null) => void;
  selectedTaskFilters: {
    personId?: string;
    status?: string;
  } | null;
}

const TotalCostRow = ({ data, t, dataSource, selectedProjects, selectedPersons, selectedRange, onTaskSelect, setSelectedTaskFilters, selectedTaskFilters }: TotalCostRowProps) => (
  <>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      <Card className="border-2 border-purple-200 hover:border-purple-300 transition-all duration-300 shadow-md hover:shadow-lg">
        <CardHeader className="bg-purple-50 rounded-t-lg">
          <CardTitle className="text-purple-800">
            {t("metrics.total_cost.title", { 
              type: dataSource === 'byAssignedTo' 
                ? t("metrics.total_cost.assignee") 
                : t("metrics.total_cost.assigner") 
            })}
          </CardTitle>
        </CardHeader>
        <CardContent className="pt-6">
          <TotalCost 
            priceMetrics={{
              ...data.priceMetrics,
              selectedProjects,
              selectedPersons,
              selectedRange
            }}
            onTaskSelect={onTaskSelect}
          />  
        </CardContent>
      </Card>

      <Card className="border-2 border-purple-200 hover:border-purple-300 transition-all duration-300 shadow-md hover:shadow-lg">
        <CardHeader className="bg-purple-50 rounded-t-lg">
          <CardTitle className="text-purple-800">{t("metrics.charts.persons.title")}</CardTitle>
        </CardHeader>
        <CardContent className="pt-6">
          {data.status && (
            <TasksPerPerson
              selectedProjects={selectedProjects}
              selectedPersons={selectedPersons}
              selectedRange={selectedRange}
              onTaskSelect={(personId, status) => {
                setSelectedTaskFilters({
                  personId,
                  status: status === 'TOTAL' ? undefined : status
                });
              }}
            />
          )}
        </CardContent>
      </Card>
    </div>

    {selectedTaskFilters && (
      <Card className="mt-6 border-2 border-purple-200 shadow-md">
        <CardHeader className="bg-purple-50 rounded-t-lg">
          <div className="flex justify-between items-center">
            <CardTitle className="text-purple-800">
              {t("metrics.charts.persons.title")}
            </CardTitle>
            <Button
              variant="ghost"
              size="icon"
              onClick={() => {
                setSelectedTaskFilters(null);
              }}
            >
              <X className="h-4 w-4" />
            </Button>
          </div>
        </CardHeader>
        <CardContent>
          <TableTask 
            personId={selectedTaskFilters.personId}
            status={selectedTaskFilters.status}
            selectedProjects={selectedProjects}
            selectedPersons={selectedPersons}
            selectedRange={selectedRange}
          />
        </CardContent>
      </Card>
    )}
  </>
);

export default function MetricsViz({
  data,
  error,
  selectedProjects,
  selectedPersons,
  selectedRange,
  showTable,
}: {
  data: MetricsData | undefined
  error: boolean
  selectedProjects: string[]
  selectedPersons: string[]
  selectedRange?: DateRange
  showTable: boolean
  setShowTable: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const { t } = useTranslation()
  const [dataSource, setDataSource] = useState('byAssignedTo')
  const [rowOrder, setRowOrder] = useState('default')
  const [selectedTaskIds, setSelectedTaskIds] = useState<string[]>([])
  const [activeSection, setActiveSection] = useState<'totalCost' | 'channel' | null>(null)
  const [activeTab, setActiveTab] = useState("charts")
  const [selectedTaskFilters, setSelectedTaskFilters] = useState<{
    personId?: string;
    status?: string;
  } | null>(null);
  const [selectedTaskFiltersChannel, setSelectedTaskFiltersChannel] = useState<{
    channelId?: string;
    status?: string;
  } | null>(null);

  const handleTaskSelect = (tasks: string[], section: 'totalCost' | 'channel' | null) => {
    setSelectedTaskIds(tasks);
    setActiveSection(section);
  };

  if (!data && !showTable) {
    return (
      <div className="flex items-center justify-center h-[60vh]">
        <Loader className="w-8 h-8 animate-spin text-purple-600" />
      </div>
    )
  }
  
  if (error && !showTable) {
    return <FetchError />
  }

  /*console.log('Complete Metrics Structure:', {
    channelMetrics: data?.channelMetrics
  });*/

  return (
    <div className="p-6 bg-gradient-to-br from-purple-50 to-white rounded-lg shadow-lg">
      <Tabs 
        value={activeTab} 
        onValueChange={setActiveTab} 
        className="space-y-6"
      >
        <div className="flex justify-between items-center">
          <TabsList className="bg-purple-100">
            <TabsTrigger value="charts" className="data-[state=active]:bg-purple-600 data-[state=active]:text-white px-6 py-2 text-base">
              {t("metrics.show_charts")}
            </TabsTrigger>
            <TabsTrigger value="table" className="data-[state=active]:bg-purple-600 data-[state=active]:text-white px-6 py-2 text-base">
              {t("metrics.show_table")}
            </TabsTrigger>
          </TabsList>
          {activeTab === "charts" && (
            <div className="flex items-center gap-4">
            <Select value={dataSource} onValueChange={setDataSource}>
            </Select>
            <div className="flex gap-1.5">
              <Button 
                variant="outline"
                size="sm"
                className="data-[state=active]:bg-purple-600 data-[state=active]:text-white px-2.5 py-1 text-xs"
                data-state={rowOrder === 'default' ? 'active' : 'inactive'}
                onClick={() => setRowOrder('default')}
              >
                {t("metrics.charts.order_buttons.status")}
              </Button>
              <Button 
                variant="outline"
                size="sm"
                className="data-[state=active]:bg-purple-600 data-[state=active]:text-white px-2.5 py-1 text-xs"
                data-state={rowOrder === 'person' ? 'active' : 'inactive'}
                onClick={() => setRowOrder('person')}
              >
                {t("metrics.charts.order_buttons.person")}
              </Button>
              <Button 
                variant="outline"
                size="sm"
                className="data-[state=active]:bg-purple-600 data-[state=active]:text-white px-2.5 py-1 text-xs"
                data-state={rowOrder === 'channel' ? 'active' : 'inactive'}
                onClick={() => setRowOrder('channel')}
              >
                {t("metrics.charts.order_buttons.channel")}
              </Button>
            </div>
            </div>
          )}  
        </div>
        <TabsContent value="charts">
          {data && (
            <div className="space-y-6">
              {rowOrder === 'default' && (
                <>
                  <CompletionStatusRow data={data} t={t} />
                  <TotalCostRow 
                    data={data} 
                    t={t} 
                    dataSource={dataSource} 
                    selectedProjects={selectedProjects}
                    selectedPersons={selectedPersons}
                    selectedRange={selectedRange}
                    onTaskSelect={(tasks) => handleTaskSelect(tasks, 'totalCost')}
                    setSelectedTaskFilters={setSelectedTaskFilters}
                    setSelectedTaskFiltersChannel={setSelectedTaskFiltersChannel}
                    setActiveSection={setActiveSection}
                    selectedTaskFilters={selectedTaskFilters}
                  />
                  {activeSection === 'totalCost' && selectedTaskIds.length > 0 && (
                    <Card className="mt-6 border-2 border-purple-200 shadow-md">
                      <CardHeader className="bg-purple-50 rounded-t-lg">
                        <div className="flex justify-between items-center">
                          <CardTitle className="text-purple-800">
                            {t("metrics.total_cost.title", { 
                              type: dataSource === 'byAssignedTo' 
                                ? t("metrics.total_cost.assignee") 
                                : t("metrics.total_cost.assigner") 
                            })}
                          </CardTitle>
                          <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => {
                              setSelectedTaskIds([]);
                              setActiveSection(null);
                            }}
                          >
                            <X className="h-4 w-4" />
                          </Button>
                        </div>
                      </CardHeader>
                      <CardContent>
                        <TableTask 
                          taskIds={selectedTaskIds}
                          selectedProjects={[]}
                          selectedPersons={[]}
                        />
                      </CardContent>
                    </Card>
                  )}
                  <ChannelMetricsRow 
                    data={data} 
                    t={t} 
                    selectedProjects={selectedProjects} 
                    selectedPersons={selectedPersons} 
                    selectedRange={selectedRange} 
                    onTaskSelect={(tasks: string[]) => handleTaskSelect(tasks, 'channel')}
                    setSelectedTaskFiltersChannel={setSelectedTaskFiltersChannel}
                    selectedTaskFiltersChannel={selectedTaskFiltersChannel}
                    selectedTaskIds={selectedTaskIds}
                    setSelectedTaskIds={setSelectedTaskIds}
                    activeSection={activeSection}
                    setActiveSection={setActiveSection}
                  />
                  
                </>
              )}
              
              {rowOrder === 'person' && (
                <>
                  <TotalCostRow 
                    data={data} 
                    t={t} 
                    dataSource={dataSource} 
                    selectedProjects={selectedProjects}
                    selectedPersons={selectedPersons}
                    selectedRange={selectedRange}
                    onTaskSelect={(tasks) => handleTaskSelect(tasks, 'totalCost')}
                    setSelectedTaskFilters={setSelectedTaskFilters}
                    setSelectedTaskFiltersChannel={setSelectedTaskFiltersChannel}
                    setActiveSection={setActiveSection}
                    selectedTaskFilters={selectedTaskFilters}
                  />
                  {activeSection === 'totalCost' && selectedTaskIds.length > 0 && (
                    <Card className="mt-6 border-2 border-purple-200 shadow-md">
                      <CardHeader className="bg-purple-50 rounded-t-lg">
                        <div className="flex justify-between items-center">
                          <CardTitle className="text-purple-800">
                            {t("metrics.total_cost.title", { 
                              type: dataSource === 'byAssignedTo' 
                                ? t("metrics.total_cost.assignee") 
                                : t("metrics.total_cost.assigner") 
                            })}
                          </CardTitle>
                          <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => {
                              setSelectedTaskIds([]);
                              setActiveSection(null);
                            }}
                          >
                            <X className="h-4 w-4" />
                          </Button>
                        </div>
                      </CardHeader>
                      <CardContent>
                        <TableTask 
                          taskIds={selectedTaskIds}
                          selectedProjects={[]}
                          selectedPersons={[]}
                        />
                      </CardContent>
                    </Card>
                  )}
                  <CompletionStatusRow data={data} t={t} />
                  <ChannelMetricsRow 
                    data={data} 
                    t={t} 
                    selectedProjects={selectedProjects} 
                    selectedPersons={selectedPersons} 
                    selectedRange={selectedRange}
                    onTaskSelect={(tasks: string[]) => handleTaskSelect(tasks, 'channel')}
                    setSelectedTaskFiltersChannel={setSelectedTaskFiltersChannel}
                    selectedTaskFiltersChannel={selectedTaskFiltersChannel}
                    selectedTaskIds={selectedTaskIds}
                    setSelectedTaskIds={setSelectedTaskIds}
                    activeSection={activeSection}
                    setActiveSection={setActiveSection}
                  />
                  
                </>
              )}
              
              {rowOrder === 'channel' && (
                <>
                  <ChannelMetricsRow 
                    data={data} 
                    t={t} 
                    selectedProjects={selectedProjects}
                    selectedPersons={selectedPersons}
                    selectedRange={selectedRange}
                    onTaskSelect={(tasks: string[]) => handleTaskSelect(tasks, 'channel')}
                    setSelectedTaskFiltersChannel={setSelectedTaskFiltersChannel}
                    selectedTaskFiltersChannel={selectedTaskFiltersChannel}
                    selectedTaskIds={selectedTaskIds}
                    setSelectedTaskIds={setSelectedTaskIds}
                    activeSection={activeSection}
                    setActiveSection={setActiveSection}
                  />
                  
                  <CompletionStatusRow data={data} t={t} />
                  <TotalCostRow 
                    data={data} 
                    t={t} 
                    dataSource={dataSource} 
                    selectedProjects={selectedProjects}
                    selectedPersons={selectedPersons}
                    selectedRange={selectedRange}
                    onTaskSelect={(tasks) => handleTaskSelect(tasks, 'totalCost')}
                    setSelectedTaskFilters={setSelectedTaskFilters}
                    setSelectedTaskFiltersChannel={setSelectedTaskFiltersChannel}
                    setActiveSection={setActiveSection}
                    selectedTaskFilters={selectedTaskFilters}
                  />
                  {activeSection === 'totalCost' && selectedTaskIds.length > 0 && (
                    <Card className="mt-6 border-2 border-purple-200 shadow-md">
                      <CardHeader className="bg-purple-50 rounded-t-lg">
                        <div className="flex justify-between items-center">
                          <CardTitle className="text-purple-800">
                            {t("metrics.total_cost.title", { 
                              type: dataSource === 'byAssignedTo' 
                                ? t("metrics.total_cost.assignee") 
                                : t("metrics.total_cost.assigner") 
                            })}
                          </CardTitle>
                          <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => {
                              setSelectedTaskIds([]);
                              setActiveSection(null);
                            }}
                          >
                            <X className="h-4 w-4" />
                          </Button>
                        </div>
                      </CardHeader>
                      <CardContent>
                        <TableTask 
                          taskIds={selectedTaskIds}
                          selectedProjects={selectedProjects}
                          selectedPersons={selectedPersons}
                          selectedRange={selectedRange}
                        />
                      </CardContent>
                    </Card>
                  )}
                </>
              )}
            </div>
          )}
        </TabsContent>
        <TabsContent value="table">
          <Card className="border-2 border-purple-200 shadow-md">
            <CardHeader className="bg-purple-50 rounded-t-lg">
              <CardTitle className="text-purple-800">{t("metrics.table_view")}</CardTitle>
            </CardHeader>
            <CardContent>
              <TableTask 
                selectedProjects={selectedProjects}
                selectedPersons={selectedPersons}
                selectedRange={selectedRange}
              />
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  )
}

function MetricsDash({ data }: { data: MetricsPayload }) {
  const { t } = useTranslation();
  
  const [_statusFilter, setStatusFilter] = useState<string | null>(null);
  const [showTable, setShowTable] = useState(false);
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  const [selectedPersons, setSelectedPersons] = useState<string[]>([]);
  const [selectedRange, setSelectedRange] = useState<DateRange | undefined>();

  const [searchProjects, setSearchProjects] = useState<string>("");
  const [searchPeople, setSearchPeople] = useState<string>("");
  

  const people = Array.from(
    new Set(
      data.projects
        .filter(
          (project) =>
            selectedProjects.length < 1 || selectedProjects.includes(project.id)
        )
        .flatMap((project) => project.people)
        .map((person) => JSON.stringify(person))
    )
  )
    .map((personString) => JSON.parse(personString))
    .filter((person) =>
      person.name.toLowerCase().includes(searchPeople.toLowerCase())
    )
    .sort((a, b) => a.name.localeCompare(b.name));

  const projects = data.projects
    .filter((project) =>
      project.name.toLowerCase().includes(searchProjects.toLowerCase())
    )
    .sort((a, b) => a.name.localeCompare(b.name));

  function handleProjectCheckboxChange(id: string) {
    setSelectedProjects((prev) =>
      prev.includes(id)
        ? prev.filter((project) => project !== id)
        : [...prev, id]
    );
  }

  function handlePersonCheckboxChange(id: string) {
    setSelectedPersons((prev) =>
      prev.includes(id) ? prev.filter((person) => person !== id) : [...prev, id]
    );
  }

  const {
    data: vizData,
    error,
    isLoading,
  } = useSWR<MetricsData>(
    `/v1/metrics${
      selectedProjects.length > 0 ||
      selectedPersons.length > 0 ||
      (selectedRange && (selectedRange.from || selectedRange.to))
        ? `?${new URLSearchParams({
            ...(selectedPersons.length > 0 && {
              people: JSON.stringify(selectedPersons),
            }),
            ...(selectedProjects.length > 0 && {
              projects: JSON.stringify(selectedProjects),
            }),
            ...(selectedRange &&
              selectedRange.from && {
                from: String(startOfDay(selectedRange.from).getTime()),
              }),
            ...(selectedRange &&
              selectedRange.to && {
                to: String(endOfDay(selectedRange.to).getTime()),
              }),
            ...(selectedProjects.length > 0 && {
              projects: JSON.stringify(selectedProjects),
            }),
          }).toString()}`
        : ""
    }`,
    fetcher
  );

  const [sidebarWidth, setSidebarWidth] = useState(384);
  const isResizing = useRef(false);
  const startX = useRef(0);
  const startWidth = useRef(0);

  const startResizing = (e: React.MouseEvent) => {
    isResizing.current = true;
    startX.current = e.clientX;
    startWidth.current = sidebarWidth;
  };

  const stopResizing = () => {
    isResizing.current = false;
  };

  const resize = (e: MouseEvent) => {
    if (!isResizing.current) return;

    const newWidth = startWidth.current + (e.clientX - startX.current);
    if (newWidth > 200 && newWidth < 600) {
      setSidebarWidth(newWidth);
    }
  };

  useEffect(() => {
    window.addEventListener("mousemove", resize);
    window.addEventListener("mouseup", stopResizing);
    return () => {
      window.removeEventListener("mousemove", resize);
      window.removeEventListener("mouseup", stopResizing);
    };
  }, []);


  //console.log('Full vizData structure:', JSON.stringify(vizData, null, 2));

  return (
    <section className="flex min-h-screen">
      <aside
        style={{ width: sidebarWidth }}
        className="relative flex-shrink-0"
      >
        {/* Sticky container for filters */}
        <div className="sticky top-0 max-h-screen overflow-y-auto border-r">
          <div className="p-4">
            <h2 className="scroll-m-20 text-2xl font-semibold tracking-tight">
              {t("metrics.filters")}
            </h2>
            <DateRangePicker
              onDateRangeChange={(data) => setSelectedRange(data)}
              className="mt-2"
            />
            <Accordion type="multiple" className="mr-6">
              <AccordionItem value="project">
                <AccordionTrigger>
                  {t("metrics.projects", { number: selectedProjects.length })}
                </AccordionTrigger>
                <AccordionContent>
                  <div>
                    <div className="py-2 flex flex-col gap-2">
                      <Button
                        variant="secondary"
                        className="w-full"
                        onClick={() => setSelectedProjects([])}
                      >
                        {t("metrics.clear")}
                      </Button>
                      <Input
                        placeholder={t("metrics.search_project")}
                        value={searchProjects}
                        onChange={(e) => setSearchProjects(e.target.value)}
                      />
                    </div>
                    {projects.map((project) => (
                      <div
                        key={project.id}
                        className="flex items-center gap-2 my-2 first:mt-0 last:mb-0"
                      >
                        <Checkbox
                          checked={selectedProjects.includes(project.id)}
                          onCheckedChange={() =>
                            handleProjectCheckboxChange(project.id)
                          }
                        />
                        <div className="flex items-center gap-2">
                          <Avatar className="w-8 h-8">
                            <AvatarImage src={project.photo} />
                            <AvatarFallback>
                              {project.name.charAt(0)}
                            </AvatarFallback>
                          </Avatar>
                          <p className="line-clamp-1">{project.name}</p>
                        </div>
                      </div>
                    ))}
                    {projects.length === 0 && <p>{t("metrics.no_project")}</p>}
                  </div>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="people">
                <AccordionTrigger>
                  {t("metrics.people", { number: selectedPersons.length })}
                </AccordionTrigger>
                <AccordionContent>
                  <div>
                    <div className="py-2 flex flex-col gap-2">
                      <Button
                        variant="secondary"
                        className="w-full"
                        onClick={() => setSelectedPersons([])}
                      >
                        {t("metrics.clear")}
                      </Button>
                      <Input
                        placeholder={t("metrics.search_people")}
                        value={searchPeople}
                        onChange={(e) => setSearchPeople(e.target.value)}
                      />
                    </div>
                    {people.map((person) => (
                      <div
                        key={person.id}
                        className="flex items-center gap-2 my-2 first:mt-0 last:mb-0"
                      >
                        <Checkbox
                          checked={selectedPersons.includes(person.id)}
                          onCheckedChange={() =>
                            handlePersonCheckboxChange(person.id)
                          }
                        />
                        <div className="flex items-center gap-2">
                          <Avatar className="w-8 h-8">
                            <AvatarImage src={person.photo} />
                            <AvatarFallback>{person.name.charAt(0)}</AvatarFallback>
                          </Avatar>
                          <p className="line-clamp-1">{person.name}</p>
                        </div>
                      </div>
                    ))}
                    {people.length === 0 && <p>{t("metrics.no_people")}</p>}
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
        </div>

        {/* Existing resize handle */}
        <div
          className="absolute top-0 right-0 h-full w-1 bg-transparent hover:bg-gray-200 cursor-col-resize transition-colors"
          onMouseDown={startResizing}
        />
      </aside>

      <main className="flex-grow p-6">
        <div className="flex items-center justify-between gap-2">
          <div className="flex items-center gap-2">
            <h1 className="scroll-m-20 text-3xl font-semibold tracking-tight first:mt-0">
              {t("metrics.title")}
            </h1>
            {isLoading && <Loader className="h-6 w-6" />}
          </div>
          
        </div>

        {vizData && (
          <div className="mt-4">
            <MetricsSummary 
              status={vizData.status || {
                PENDING: 0,
                IN_PROGRESS: 0,
                DONE: 0,
                DONE_WITH_ISSUES: 0,
                IN_REVIEW: 0,
              }}
              completenessLate={vizData.completenessLate}
              total={vizData.completeness.total}
              setStatusFilter={setStatusFilter}
              setShowTable={setShowTable}
            />
          </div>
        )}

        <MetricsViz
          data={vizData}
          error={error}
          selectedProjects={selectedProjects}
          selectedPersons={selectedPersons}
          selectedRange={selectedRange}
          showTable={showTable}
          setShowTable={setShowTable}
        />
      </main>
    </section>
  );
}

export function Metrics() {
  const { data, error } = useSWR<MetricsPayload>(
    "/v1/metrics/payload",
    fetcher
  );

  if (!data) return <Loading />;
  if (error) return <FetchError />;

  if (data) return <MetricsDash data={data} />;


  ;
}
