"use client";

import { useState, useEffect, useRef } from "react";
import { Search } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import useDebounceState from "@/hooks/debounce";
import useSWR from "swr";
import { fetcher } from "@/lib/fetcher";

interface Place {
  place: string;
  lat: string;
  lng: string;
}

export function LocationPicker({
  onSelect,
}: {
  onSelect: (written_ubication: string, lat: string, lng: string) => void;
}) {
  const [inputValue, debouncedValue, setInputValue] = useDebounceState<string>(
    "",
    1000
  );
  const [selectedPlace, setSelectedPlace] = useState<Place | null>(null);
  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(false);
  const autocompleteRef = useRef<HTMLDivElement>(null);

  const { data, error, isLoading } = useSWR<Place[]>(
    debouncedValue ? `/v1/utils/task/places?value=${debouncedValue}` : null,
    fetcher,
    { revalidateOnFocus: false }
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        autocompleteRef.current &&
        !autocompleteRef.current.contains(event.target as Node)
      ) {
        setIsAutocompleteOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setIsAutocompleteOpen(true);
  };

  const handleSelectPlace = (place: Place) => {
    setSelectedPlace(place);
    setInputValue(place.place);
    setIsAutocompleteOpen(false);
    onSelect(place.place, place.lat, place.lng);
  };

  const handleChangePlaceClick = () => {
    setSelectedPlace(null);
    setInputValue("");
    setIsAutocompleteOpen(true);
  };

  return (
    <div className="relative w-full max-w-sm">
      {selectedPlace ? (
        <div className="flex items-center justify-between p-2 border rounded-md">
          <span className="truncate">{selectedPlace.place}</span>
          <Button
            variant="ghost"
            size="sm"
            onClick={handleChangePlaceClick}
            className="ml-2"
          >
            Change
          </Button>
        </div>
      ) : (
        <div className="relative">
          <Input
            type="text"
            placeholder="Search for a place..."
            value={inputValue}
            onChange={handleInputChange}
            className="pr-10"
          />
          <Search
            className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400"
            size={20}
          />
        </div>
      )}

      {isAutocompleteOpen && !selectedPlace && (
        <div
          ref={autocompleteRef}
          className="absolute z-10 w-full mt-1 bg-white border rounded-md shadow-lg max-h-60 overflow-auto"
        >
          {isLoading && (
            <div className="p-2 text-sm text-gray-500">Loading...</div>
          )}
          {error && (
            <div className="p-2 text-sm text-red-500">
              Error: {error.message}
            </div>
          )}
          {!isLoading && !error && data && data.length === 0 && (
            <div className="p-2 text-sm text-gray-500">No places found</div>
          )}
          {data && data.length > 0 && (
            <ul>
              {data.map((place) => (
                <li
                  key={`${place.lat}-${place.lng}`}
                  onClick={() => handleSelectPlace(place)}
                  className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
                >
                  {place.place}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
}
