import { t } from "i18next";

export function Importance({
  importance,
}: {
  importance: "LOW" | "MEDIUM" | "HIGH";
}) {
  const stars = {
    LOW: 1,
    MEDIUM: 2,
    HIGH: 3,
  };

  return (
    <div className="my-2 flex items-center gap-1">
      {[...Array(stars[importance])].map((_, i) => (
        <svg
          key={i}
          width="26"
          height="26"
          viewBox="0 0 24 24"
          fill="#FFFF00"
          stroke="#FFD700"
          strokeWidth="2"
        >
          <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
        </svg>
      ))}
      <p>({t(`ui.importance.${importance}`)})</p>
    </div>
  );
}
