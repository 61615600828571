import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Progress } from "@/components/ui/progress";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Loader2 } from "lucide-react";

interface ExportModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (amount: number) => void;
  totalTasks: number;
  isExporting: boolean;
  progress?: number;
}

export function ExportModal({
  isOpen,
  onClose,
  onConfirm,
  isExporting,
  progress = 0,
}: ExportModalProps) {
  const [amount, setAmount] = useState<string>("");
  const [error, setError] = useState<string>("");
  const { t } = useTranslation();

  const handleConfirm = () => {
    const numAmount = parseInt(amount);
    if (isNaN(numAmount) || numAmount <= 0) {
      setError(t("export.invalid_amount"));
      return;
    }
    onConfirm(numAmount);
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("export.custom_amount_title")}</DialogTitle>
          <DialogDescription>
            {t("export.custom_amount_description")}
          </DialogDescription>
        </DialogHeader>
        <div className="py-4 space-y-4">
          <Input
            type="number"
            value={amount}
            onChange={(e) => {
              setAmount(e.target.value);
              setError("");
            }}
            placeholder={t("export.amount_placeholder")}
            min={1}
            disabled={isExporting}
          />
          {error && <p className="text-sm text-red-500">{error}</p>}

          {isExporting && (
            <div>
              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <Loader2 className="w-4 h-4 animate-spin" />
                  <span className="text-sm">
                    {t("export.progress", { progress: Math.round(progress) })}
                  </span>
                </div>
                <Progress value={progress} />
              </div>
              <Progress value={progress} />
            </div>
          )}
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={onClose} disabled={isExporting}>
            {t("export.cancel")}
          </Button>
          <Button onClick={handleConfirm} disabled={isExporting}>
            {isExporting ? t("export.exporting") : t("export.confirm")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
